import React from 'react';
import '../projects-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.png'
import pic1 from './pic1.png'
import pic2 from './pic2.png'
import pic3 from './pic3.png'
import pic4 from './pic4.jpg'
import pic5 from './pic5.jpg'

const Page = () => {
   
    const detail = 
        {
            id:5,
            imgSrc:cover,
            ini:'Thailand VERDI-RECOVER study Chiang Mai',
            topic:'Thailand VERDI-RECOVER study Chiang Mai',
            inform:`SARS-CoV-2 outbreak was first reported in the city of Wuhan, China, in December 2019. The WHO declared the virus a Public Health Emergency of International Concern (PHEIC) on 30 January 2020 and a pandemic on 11 March 2020. As of 1 March 2022, more than 578 million cases and 6.4 million deaths have been reported to WHO from all continents.The Thai partners in VERDI, Mahidol University and Chiang Mai University are both leading universities with accredited virology laboratories working to diagnose incident SARS CoV-2 infections in the community and are therefore ideally place to identify eligible participants to invite to participate in this study and enroll participants into this study. Both partners have the infrastructure and capacity to enroll, follow up study participants, securely collect, manage and analyze the data, collect transport and store samples and conduct sequencing of variants.`,
            link1:`https://lucent.ams.cmu.ac.th/verdi.php`,
            link2:`https://www.facebook.com/people/VERDI-RECOVER-study-Thailand/100082960345322/`,
            }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }
        const handleLink2 = () =>{
            window.open(detail.link2, '_blank', 'noreferrer')
        }
        
    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
        },
        {
            imageSrc: pic3,
            alt: 'pic3',
            title: 'pic3',
        },
        {
            imageSrc: pic4,
            alt: 'pic4',
            title: 'pic4',
        },
        {
            imageSrc: pic5,
            alt: 'pic5',
            title: 'pic5',
        },
    ]

    return (
        <div className='elements containers'>
           <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{Parse(detail.topic)}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                    <button onClick={handleLink2}>{detail.link2}</button>
                </div>
            </div>
        </div>
    );
}

export default Page;
