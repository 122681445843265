import React from 'react';
import '../../news-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpg'
import pic3 from './pic3.jpg'
import pic4 from './pic4.jpg'


const Id3 = () => {

    const detail = 
        {
            id:3,
            imgSrc:cover,
            ini:'What research should be conducted to prevent future epidemics/pandemics in Southeast Asia?',
            href:'',
            element:'',
            topic:'',
            inform:`A brainstorming meeting on regional research strategies in the face of epidemics, initiated by the members of the IJL PRESTO, gathered about 40 participants was held in Chiang Mai (Thailand) in March 2023.`,
            link1:`https://en.ird.fr/ijl-presto-review-regional-medical-research-meeting`
         }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
            thumbnailImageSrc: cover
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
            thumbnailImageSrc: pic1
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
            thumbnailImageSrc: pic2
        },
        {
            imageSrc: pic3,
            alt: 'pic3',
            title: 'pic3',
            thumbnailImageSrc: pic3
        },
        {
            imageSrc: pic4,
            alt: 'pic4',
            title: 'pic4',
            thumbnailImageSrc: pic4
        }
    ]

    return (
        <div className='elements containers'>
           <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{Parse(detail.topic)}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                </div>
            </div>
        </div>
    );
}

export default Id3;
