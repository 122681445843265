import './css/error.css'

const ErrorPage = () =>{

    return(
        <div className="error">
                <div className="cover">
                <div className="cover-text">
                    <h1>ERROR PAGE NOT FOUND</h1>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage;