import React from 'react';
import '../projects-pages.css'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'

const Immana = () => {

    const detail = 
        {
            id:7,
            imgSrc:cover,
            ini:'THE IMMANA project',
            topic:'',
            inform:`The IMMANA-funded project “Activity Spaces and Household Exclusion from Food Environments” is led by the University of Reading (Great-Britain) in collaboration with IRD Laos and Chiang Mai University and supported by a two-year £249,000 award from the Innovative Methods and Metrics for Agriculture and Nutrition Actions (IMMANA) programme – coordinated by the London School for Tropical Medicine and Hygiene and co-funded with UK Aid from the UK government through the Foreign, Commonwealth & Development Office (FCDO) and the Bill & Melinda Gates Foundation. The project studied food environments, food security, and the daily food practices of 240 households across eight rural and urban communities in a comparative study between Vientiane Province, Laos, and Chiang Mai Province, Thailand.`,
            link1:`https://en.ird.fr/immana-project-ird-led-team-completes-innovative-data-collection`
        }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
        }
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{detail.topic}</h2>
                <p>{detail.inform}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                </div>
            </div>
        </div>
    );
}

export default Immana;
