import React from 'react';
import '../projects-pages.css'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.png'
import pic1 from './pic1.png'
import pic2 from './pic2.png'

const Bilao = () => {

    const detail = {
        id:3,
        ini:'BILAO',
        topic:'',
        inform:`The aim of the BILAO exploratory research project in Laos was to assess the presence and potential emergence of MBVs (Mosquito-Borne Viruses) associated with domestic birds in rural and peri-urban areas. Using molecular xenomonitoring (a technique for detecting the virus in the excrement of trapped mosquitoes), more than 300 mosquitoes and 30 filter papers containing their excrement were collected.
The next stage will involve continuing the research in Laos by exploring other collection sites in order to target mosquitoes associated with wild avian fauna, as well as extending this type of project to other countries in South-East Asia, in order to better identify the factors responsible for the spread of these viruses in human and animal populations.
`,
        href: `BILAO`,
        link1:`https://ohsea.ird.fr/en/bilao/?doing_wp_cron=1720425122.3644750118255615234375`
    }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
        }
    ]

    const handleLink1 = () =>{
        window.open(detail.link1, '_blank', 'noreferrer')
    }

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <p>{detail.inform}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                </div>
            </div>
        </div>
    );
}

export default Bilao;
