import React, { useEffect, useState } from 'react'
import './css/news.css'

import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator'

import { Link, Route, Routes } from 'react-router-dom'

// Workshop
import id1img from './news/workshop/Bilao Medical entomology course/cover.jpg'
import id1page from './news/workshop/Bilao Medical entomology course/index'
import id2img from './news/workshop/Engaging Laotian pharmacy students/cover.jpg'
import id2page from './news/workshop/Engaging Laotian pharmacy students/index'
import id3img from './news/workshop/Participatory approach in the context of One Health Social Sciences/cover.jpg'
import id3page from './news/workshop/Participatory approach in the context of One Health Social Sciences/index'
import id4img from './news/workshop/Preparing to respond to calls for tender/cover.JPG'
import id4page from './news/workshop/Preparing to respond to calls for tender/index'

// News
import id12img from './news/news/First parasitology training course on nematodes at CIREN/cover.jpg'
import id12page from './news/news/First parasitology training course on nematodes at CIREN/index'
import id11img from './news/news/parasite_MEDCMU_241211/cover.jpg'
import id11page from './news/news/parasite_MEDCMU_241211/index'
import id10img from './news/news/Lunch Seminar_241209/cover.jpg'
import id10page from './news/news/Lunch Seminar_241209/index'
import id5img from './news/news/Boung Phao Food Festival/cover.jpg'
import id5page from './news/news/Boung Phao Food Festival/index'
import id6img from './news/news/What research should be conducted to prevent future epidemicspandemics in Southeast Asia/cover.jpg'
import id6page from './news/news/What research should be conducted to prevent future epidemicspandemics in Southeast Asia/index'
import id7img from './news/news/Poster presentation in SEAOHUN 2024 International Conference in Chiang Mai Thailand/cover.jpg'
import id7page from './news/news/Poster presentation in SEAOHUN 2024 International Conference in Chiang Mai Thailand/index'
import id8img from './news/news/The 14th International Public Health Conference among Greater Mekong Sub-regional Countries/cover.jpg'
import id8page from './news/news/The 14th International Public Health Conference among Greater Mekong Sub-regional Countries/index'

// training
import id9img from './news/training/Disease Ecology Symposium and Training 28 Nov - 2 Dec 2022/cover.jpg'
import id9page from './news/training/Disease Ecology Symposium and Training 28 Nov - 2 Dec 2022/index'
// Others

import ErrorPage from './error'

const News = () => {
  const handleUp = () =>{
    window.scrollTo({top:0})
}

    const [active, setActive] = useState('')
    const handlePopup = (e) =>{
        const key = e.currentTarget.getAttribute('data-key')
        setActive(prevActive => prevActive === key ? '' : key)
    }

    useEffect(()=>{
        const popUp = () =>{
            if(active){
                const elements = document.querySelectorAll(`.containers ${active}`)
                elements.forEach(element =>{
                    element.classList.toggle('active')
                })
            }else{
                return
            }
        }
        popUp()
    },[active])

    // FILTER Catalogs
    const [filterInput, setFilterInput] = useState('')
    const [filteredData, setFilterData] = useState([])

    const [search, setSearch] = useState('')
    const handleSearch = (e) =>{
        const value = e.target.value
        setFilterInput(value)
        setSearch(value)

        const filtered = newss.filter((news)=>
            news.ini.toLowerCase().includes(value.toLowerCase())
        )
        setFilterData(filtered)
    }

    const [topic, setTopic] = useState(false)
    const handleDropdown = (e) =>{
        const value = e.target.value
        setFilterInput(value)
        setTopic(value)

        const filtered = newss.filter((news) =>
            news.cat.toString().includes(value.toString())
        )
        setFilterData(filtered)  
    }

    const [Year, setYear] = useState(false)
    const handleYear = (e) =>{
        const value = e.target.value
        setYear(value)
        setFilterInput(value)

        const filtered = newss.filter((news) =>
            news.year.toString().includes(value.toString())
        )
        setFilterData(filtered)
    }

    const topics = [
        {
            id: 0,
            name: 'All',
            group: false
        },
        {
            id: 1,
            name: 'NEWS & ACTIVITIES',
            group: 'news'
        },
        {
            id: 2,
            name: 'TRAINING',
            group: 'training'
        },
        {
            id: 3,
            name: 'WORKSHOP',
            group: 'workshop'
        },
        {
            id: 4,
            name: 'OTHERS',
            group: 'others'
        },
    ]

    const years = [
        {
            id: 0,
            year: false,
            label: 'All'
        },
        {
            id:3,
            year: 2024,
            label: 2024
        },
        {
            id:2,
            year: 2023,
            label: 2023
        },
        {
            id: 1,
            year: 2022,
            label: 2022
        },
    ]

    const newss = [
        {
            id:12,
            year: 2024,
            cat: 'news',
            imgSrc: id12img,
            ini:'First parasitology training course on nematodes at CIREN',
            href: `First parasitology training course on nematodes at CIREN`,
            element: id12page,
            inform:`As part of IJL PRESTO activities, a parasitology workshop at CIREN (Nakaï, Laos) brought together 14 participants to learn about non-invasive sample collection and laboratory analysis of parasites with zoonotic transmission potential, with a specific focus on nematodes.`,
        },
        {
            id:11,
            year: 2024,
            cat: 'news',
            imgSrc: id11img,
            ini:'Explore oppotunities and strategies for improving education and research with Department of Parasitology, Faculty of Medicine, Chiang Mai University"',
            href: `parasite_MEDCMU_241211`,
            element: id11page,
            inform:`On December 11, 2024, Dr. Dorothée Missé, Dr.Dorothée Missé co-heads the “Biology of Viral Infection” Department at IRD MIVEGEC Unit, France
            met with the Department of Parasitology, Faculty of Medicine, Chiang Mai University, to explore opportunities and strategies for developing education and research collaboration.`,
        },
        {
            id:10,
            year: 2024,
            cat: 'news',
            imgSrc: id10img,
            ini:'LUNCH SEMINAR in the topic of "ARBOVIRUS TRANSMISSION AND PATHOGENESIS"',
            href: `Lunch Seminar_241209`,
            element: id10page,
            inform:`Dr Dorothée Missé co-heads the “Biology of Viral Infection” Department at IRD MIVEGEC Unit. She works on the pathogenesis of emerging and re-emerging viruses (Dengue, Chikungunya, Zika, Mayaro...viruses) with a focus on virus-host interactions and innate immunity and antiviral compound discovery. She is involved in various "One Health" projects in Africa  (Zimbabwe & Cameroon). Dr Missé is also a nominated member of the CBS2 Doctoral School office at the Montpellier University of Excellence.`,
        },
        {
            id:9,
            year: 2024,
            cat: 'news',
            imgSrc: id7img,
            ini:'Poster presentation in "SEAOHUN 2024 International Conference in Chiang Mai, Thailand"',
            href: `Poster presentation in SEAOHUN 2024 International Conference in Chiang Mai, Thailand`,
            element: id7page,
            inform:`On September 19, 2024, Ms. Premmarin Inmonthian, a master's degree student of Assistant Professor Dr. Wuttichai Kamduang in the International Program of Medical Technology at Chiang Mai University, presented her research in a poster presentation titled "Identification of Respiratory Viruses in Wildlife Contacts: A Study from Omkoi District, Thailand." This took place during the SEAOHUN 2024 International Conference in Chiang Mai, Thailand, under the theme "One Health in Action," held at the Shangri-La Hotel, Chiang Mai.`
        },
        {
            id:8,
            year: 2024,
            cat: 'news',
            imgSrc: id8img,
            ini:'The 14th International Public Health Conference among Greater Mekong Sub-regional Countries.',
            href: `The 14th International Public Health Conference among Greater Mekong Sub-regional Countries`,
            element: id8page,
            inform:`On June 27 - 29, 2024, Miss Premarin Inmontian, a Master degree student in the field of Medical Technology (International Program) at the Faculty of Medical Technology, Chiang Mai University, with Assistant Professor Dr. Woottichai Khamduang as her advisor, participated and presented research results in the form of a poster presentation on the topic ""Identification of Respiratory Viruses in Wildlife Contacts: A Study from Omkoi District, Thailand at the international academic conference “The 14th International Public Health Conference among Greater Mekong Sub-regional Countries.” The conference is theme was Meeting the Sustainable Development Goals through Improved Nutrition, Health Equity, and Climate Change Adaptation"" and was held at Pullman Luang Prabang Hotel, Luang Prabang, Lao People is Democratic Republic.`
        },
        {
            id:7,
            year: 2023,
            cat: 'news',
            imgSrc: id6img,
            ini:'What research should be conducted to prevent future epidemicspandemics in Southeast Asia',
            href: `What research should be conducted to prevent future epidemicspandemics in Southeast Asia`,
            element: id6page,
            inform:`The main aim of the project is to monitor the origin of SARS-CoV2 in natural environments sharing biogeographical and socio-ecological characteristics with south-west China (regions of northern Thailand and Lao PDR). The aim is to characterise the natural cycle of SARS-CoV2 and how it emerges in humans. The zoonotic/emergence risk of Sarbecoviruses linked to SARS-CoV2 infecting wild animals in northern Southeast Asia will be estimated using a model that integrates phylodynamic data/analyses with socio-ecological factors, in order to develop genuine strategies for anticipating and preventing future emergences.`
        },
        {
            id:6,
            year: 2023,
            cat: 'news',
            imgSrc: id5img,
            ini:'Boung Phao Food Festival',
            href: `Boung Phao Food Festival`,
            element: id5page,
            inform:`The main aim of the project is to monitor the origin of SARS-CoV2 in natural environments sharing biogeographical and socio-ecological characteristics with south-west China (regions of northern Thailand and Lao PDR). The aim is to characterise the natural cycle of SARS-CoV2 and how it emerges in humans. The zoonotic/emergence risk of Sarbecoviruses linked to SARS-CoV2 infecting wild animals in northern Southeast Asia will be estimated using a model that integrates phylodynamic data/analyses with socio-ecological factors, in order to develop genuine strategies for anticipating and preventing future emergences.`
        },
        {
            id:5,
            year: 2023,
            cat: 'workshop',
            imgSrc: id4img,
            ini:'Preparing to respond to calls for tender',
            href: `Preparing to respond to calls for tender`,
            element: id4page,
            inform:`The main aim of the project is to monitor the origin of SARS-CoV2 in natural environments sharing biogeographical and socio-ecological characteristics with south-west China (regions of northern Thailand and Lao PDR). The aim is to characterise the natural cycle of SARS-CoV2 and how it emerges in humans. The zoonotic/emergence risk of Sarbecoviruses linked to SARS-CoV2 infecting wild animals in northern Southeast Asia will be estimated using a model that integrates phylodynamic data/analyses with socio-ecological factors, in order to develop genuine strategies for anticipating and preventing future emergences.`
        },
        {
            id:4,
            year: 2023,
            cat: 'workshop',
            imgSrc: id3img,
            ini:'Participatory approach in the context of One Health Social Sciences',
            href: `Participatory approach in the context of One Health Social Sciences`,
            element: id3page,
            inform:`The main aim of the project is to monitor the origin of SARS-CoV2 in natural environments sharing biogeographical and socio-ecological characteristics with south-west China (regions of northern Thailand and Lao PDR). The aim is to characterise the natural cycle of SARS-CoV2 and how it emerges in humans. The zoonotic/emergence risk of Sarbecoviruses linked to SARS-CoV2 infecting wild animals in northern Southeast Asia will be estimated using a model that integrates phylodynamic data/analyses with socio-ecological factors, in order to develop genuine strategies for anticipating and preventing future emergences.`
        },
        {
            id:3,
            year: 2023,
            cat: 'workshop',
            imgSrc: id2img,
            ini:'Engaging Laotian pharmacy students',
            href: `Engaging Laotian pharmacy students`,
            element: id2page,
            inform:`The main aim of the project is to monitor the origin of SARS-CoV2 in natural environments sharing biogeographical and socio-ecological characteristics with south-west China (regions of northern Thailand and Lao PDR). The aim is to characterise the natural cycle of SARS-CoV2 and how it emerges in humans. The zoonotic/emergence risk of Sarbecoviruses linked to SARS-CoV2 infecting wild animals in northern Southeast Asia will be estimated using a model that integrates phylodynamic data/analyses with socio-ecological factors, in order to develop genuine strategies for anticipating and preventing future emergences.`
        },
        {
            id:2,
            year: 2023,
            cat: 'workshop',
            imgSrc: id1img,
            ini:'Bilao Medical entomology course',
            href: `Bilao Medical entomology course`,
            element: id1page,
            inform:`The main aim of the project is to monitor the origin of SARS-CoV2 in natural environments sharing biogeographical and socio-ecological characteristics with south-west China (regions of northern Thailand and Lao PDR). The aim is to characterise the natural cycle of SARS-CoV2 and how it emerges in humans. The zoonotic/emergence risk of Sarbecoviruses linked to SARS-CoV2 infecting wild animals in northern Southeast Asia will be estimated using a model that integrates phylodynamic data/analyses with socio-ecological factors, in order to develop genuine strategies for anticipating and preventing future emergences.`
        },
        {
            id:1,
            year: 2022,
            cat: 'training',
            imgSrc: id9img,
            ini:'Disease Ecology Symposium and Training: 28 Nov - 2 Dec 2022',
            href: `Disease Ecology Symposium and Training 28 Nov - 2 Dec 2022`,
            element: id9page,
            inform:`This program is designed for researchers, scientists, and post-graduate students interested in the fields of disease ecology, biodiversity or other related fields such as conservation medicine, veterinary epidemiology, zoology, parasitology and microbiology who are seeking field, laboratory and computational trainings in monitoring of biodiversity, wildlife population, wildlife-borne diseases, disease reservoirs and vectors in tropical area. At Tropical Disease Research Centre (TDRC), Faculty of Tropical Medicine, Mahidol University Kanchanaburi Campus (MUKA), Sai Yok, Kanchanaburi, Thailand.`
        },
    ]

    // Maximum containers
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerpage, setRowPerPage] = useState(8)

    // calculate data display
    const paginatedData = newss.slice(currentPage * rowsPerpage, (currentPage + 1) * rowsPerpage)
    // Handle page change
    const onPageChange = (e) => {
        setCurrentPage(e.page)
        setRowPerPage(e.rows)
        handleUp()
    }

    const newsRoutes = newss.map((news) => (
        <Route path={`${news.cat}/${news.href}`} element={<news.element />} key={news.id} />
    ))
    const dataToDisplay = filterInput ? filteredData : paginatedData
    const totalRecords = filterInput ? filteredData.length : newss.length


    return(
        <div className="news">
            <div className="people-modal" style={{display: active? 'block' : 'none'}} onClick={handlePopup}></div>
            <div className="cover">
                <div className="cover-bg"></div>
                <div className="cover-text flyIn">
                    <h1>NEWS & ACTIVITIES</h1>
                </div>
            </div>

            <Routes>
                <Route index element={
                <div>
                    {/* Search input */}
                <div className="filter">
                    <div className="items search">
                        <div className="head">
                            <b>Search: </b>
                        </div>
                        <div className="search">
                            <InputText type="text" placeholder="enter search term..." onChange={handleSearch} value={search} disabled={topic !== false || Year !== false}/>
                            <i className="pi pi-search"></i>
                        </div>
                    </div>
                    <div className="dropdown">
                        {/* FILTER by Cat */}
                            <div className="items dropdown aims">
                                <div className="head">
                                    <b>Type: </b>
                                </div>
                                <div className="aims">
                                    <Dropdown value={topic} onChange={handleDropdown} options={topics} optionLabel='name' optionValue='group' placeholder='All' disabled={search !== '' || Year !== false}/>
                                </div>
                            </div>
                        {/* FILTER by YEAR */}
                            <div className="items dropdown year">
                                <div className="head">
                                    <b>Year: </b>
                                </div>
                                <div className="year">
                                    <Dropdown value={Year} onChange={handleYear} options={years} optionLabel='label' optionValue='year' placeholder='All' disabled={search !== '' || topic !== false}/>
                                </div>
                            </div>
                    </div>
                </div>
                  <div className="news-activities">
                    {dataToDisplay.map((news) => (
                      <div className={`containers ${news.id}`} key={news.id}>
                        <div className="items">
                          <div className="img">
                            <img src={news.imgSrc} alt="item" loading='lazy'/>
                            {/* <div className="tags">
                                {Array.isArray(news.aim) && news.aim.map((aim) => (
                                    <div className="aim">
                                        {aim}
                                    </div>
                                ))}
                                <div className="year">
                                    {news.year}
                                </div>
                            </div> */}
                          </div>
                          <div className='ini'>
                            <h2>{news.ini}</h2>
                          </div>
                          <div className='inform'>
                            <p className='text'>{news.inform}</p>
                          </div>
                          <div className="btn">
                            <Link to={`${news.cat}/${news.href}`} id={news.href}>SEE MORE</Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="paginator">
                        <Paginator
                            first={currentPage * rowsPerpage}
                            rows={rowsPerpage}
                            totalRecords={totalRecords}
                            onPageChange={onPageChange}
                        />
                    </div>
                </div>
                }/>
                {newsRoutes}
                <Route path='*' element={<ErrorPage/>} />

            </Routes>

        </div>
    )
}

export default News