import React from 'react';
import '../../news-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpg'
import pic3 from './pic3.jpg'

const Page = () => {

    const detail = 
        {
            id:1,
            imgSrc:cover,
            ini:'The 14th International Public Health Conference among Greater Mekong Sub-regional Countries.',
            href:'',
            element:'',
            topic:'',
            inform:`"On June 27 - 29, 2024, Miss Premarin Inmontian, a Master degree student in the field of Medical Technology (International Program) at the Faculty of Medical Technology, Chiang Mai University, with Assistant Professor Dr. Wuttichai Khamduang as her advisor, participated and presented research results in the form of a poster presentation on the topic ""Identification of Respiratory Viruses in Wildlife Contacts: A Study from Omkoi District, Thailand at the international academic conference “The 14th International Public Health Conference among Greater Mekong Sub-regional Countries.” The conference is theme was Meeting the Sustainable Development Goals through Improved Nutrition, Health Equity, and Climate Change Adaptation"" and was held at Pullman Luang Prabang Hotel, Luang Prabang, Lao People is Democratic Republic."`
        ,
            link1:`https://www.facebook.com/photo/?fbid=444181545252670&set=pcb.444181918585966`,
        }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
            thumbnailImageSrc: cover
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
            thumbnailImageSrc: pic1
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
            thumbnailImageSrc: pic2
        },
        {
            imageSrc: pic3,
            alt: 'pic3',
            title: 'pic3',
            thumbnailImageSrc: pic3
        },
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{Parse(detail.topic)}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                </div>
            </div>
        </div>
    );
}

export default Page;
