import React from 'react';

import '../projects-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './pic1.jpg'

const Lacoviss = () => {


    const detail = 
        {
            id:9,
            imgSrc: `${process.env.PUBLIC_URL}/research/Lacoviss/cover.jpg`,
            ini:'LACOVISS (LAo COronavirus Investigation par une Seule Santé)',
            topic:'One-Health approach in Epidemiological investigation of SARS-CoV-2 in Laos.',
            inform:`The Respiratory infection COVID-19, due to a new coronavirus, SARS-CoV2, appeared in December 2019 in several people who attended a wildlife market in Wuhan, China. While COVID-19 has spread to nearly 200 countries and caused over 16 million infections, Lao PDR detected its first confirmed case, three months after the start of the outbreak in China. 
As of today, Lao PDR has only recorded 19 confirmed cases and no death, a very low number compared to other countries around the world.
However, several key factors suggest that Lao PDR could be much more affected by COVID-19 because: 
<ul>
    <li>The multiple and massive trans-border movements between Lao PDR and China. </li>
    <li>The similar biogeographical and socio-ecological characteristics with South-Western China.</li>
    <li>The detection of a high diversity of Betacoronavirus sequences in several species of bats in Lao PDR. The numerous markets selling local wildlife including bats and pangolins.</li>
</ul>
<i>LACOVISS</i> project aims at investigating, using a ONE-HEALTH approach, this unexpected epidemiological pattern of SARS-CoV-2 in Lao PDR by bringing together an interdisciplinary team of experts in the field from the Institute of Research for Development (IRD), the University of Caen, the Center of Infectiology Lao-Christophe Mérieux (CILM) and the National Animal Health Laboratory (NAHL) in Vientiane. We will focus on a community-based cohort of 1092 households, including 5400 study participants, followed-up between March 2015 and February 2019 for influenza-like illness (ILI) investigation and causative agents detection (LACORIS project), located in the Vientiane metropolitan area. We will track SARS-CoV-2, retrospectively and prospectively, in all potential actors in SARS-CoV-2 circulation, including humans, domestic animals, and wildlife.
`,
            link1:`https://en.ird.fr/node/9136`
        }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
        }
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{detail.topic}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                </div>
            </div>
        </div>
    );
}

export default Lacoviss;
