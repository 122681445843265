import { useCallback, useEffect, useState } from 'react';
import {
  ReactFlow,
  applyEdgeChanges,
  applyNodeChanges,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';

import TextUpdaterNode from './TextUpdaterNode.js';
import OutputNode from './outputNode.js';

import './text-updater-node.css';

function Flow() {

  // Check Phone width
  const [mobile, setMobile] = useState(window.innerWidth <= 1000)
  // Update mobile state when window resizes
  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 1000);
    };
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
  }, []);

  const initialNodes = [
    {
      id: 'presto',
      type: 'textUpdater',
      position: mobile ? { x: 175, y: 150 } : { x: 800, y: 200 },
      data: { value: 'PRESTO' },
    },
    {
      id: 'lucent',
      type: 'outputNode',
      position: mobile ? { x: 0, y: 0 } : { x: 200, y: 150 },
      data: { 
          value: 'LUCENT International Collaboration, (THAILAND)' ,
          // li: [
          //     'MOOC', 
          //     'Molecular laboratory', 
          //     'Serology laboratory'
          // ],
          link: 'https://lucent.ams.cmu.ac.th/'
      },
    },
    {
      id: 'podd',
      type: 'outputNode',
      position: mobile ? { x: 300, y: 250 } : { x: 1000, y: 350 },
      data: { 
          value: 'Participatory One health Disease Detection (PoDD), (THAILAND)',
          link: 'https://podd.cmu.ac.th/'
      },
    },
    {
      id: 'ciren',
      type: 'outputNode',
      position: mobile ? { x: 0, y: 250 } : { x: 600, y: 400 },
      data: { 
          value: 'CIREN, (LAO PDR)',
          link: 'https://en.ird.fr/cirens-activities-start-training-deployment-technological-tools'
      },
    },
    {
      id: 'DNP_CD',
      type: 'outputNode',
      position: mobile ? { x: 300, y: 100 } : { x: 900, y: 50 },
      data: { 
          value: 'Doi Chiang Dao Wildlife Research Station, Chiang Mai Province, (THAILAND)',
          link: 'https://www.facebook.com/DoiChiangDaoWildlifeResearchStation/'
      },
    },
  ];
  
  const initialEdge = [
      {
          id: 'presto-lucent',
          source: 'presto',
          target: 'lucent',
          targetHandle: 'right',
          sourceHandle: 'left'
      },
      {
          id: 'presto-podd',
          source: 'presto',
          target: 'podd',
          targetHandle: 'left',
          sourceHandle: 'right'
      },
      {
          id: 'presto-ciren',
          source: 'presto',
          target: 'ciren',
          targetHandle: 'top',
          sourceHandle: 'bottom'
      },
      {
        id: 'presto-dnp_cd',
        source: 'presto',
        target: 'DNP_CD',
        targetHandle: 'left',
        sourceHandle: 'top'
    }
  ]
  // we define the nodeTypes outside of the component to prevent re-renderings
  // you could also use useMemo inside the component
  const nodeTypes = {
      textUpdater: TextUpdaterNode ,
      outputNode: OutputNode
  }

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdge);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes],
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges],
  );
//   const onConnect = useCallback(
//     (connection) => setEdges((eds) => addEdge(connection, eds)),
//     [setEdges],
//   );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      minZoom={1}
      maxZoom={1}
    />
  );
}

export default Flow;
