import React from 'react';
import '../../news-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpg'
import pic3 from './pic3.jpg'
import pic4 from './pic4.jpg'
import pic5 from './pic5.jpg'


const Id2 = () => {

    const detail = 
        {
            id:2,
            imgSrc:cover,
            ini:'Disease Ecology Symposium and Training: 28 Nov - 2 Dec 2022',
            href:'',
            element:'',
            topic:'',
            inform:`This program is designed for researchers, scientists, and post-graduate students interested in the fields of disease ecology, biodiversity or other related fields such as conservation medicine, veterinary epidemiology, zoology, parasitology and microbiology who are seeking field, laboratory and computational trainings in monitoring of biodiversity, wildlife population, wildlife-borne diseases, disease reservoirs and vectors in tropical area. At Tropical Disease Research Centre (TDRC), Faculty of Tropical Medicine, Mahidol University Kanchanaburi Campus (MUKA), Sai Yok, Kanchanaburi, Thailand.`
        ,
            link1:`https://www.facebook.com/BiodiversityandHealth`,
        }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
            thumbnailImageSrc: cover
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
            thumbnailImageSrc: pic1
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
            thumbnailImageSrc: pic2
        },
        {
            imageSrc: pic3,
            alt: 'pic3',
            title: 'pic3',
            thumbnailImageSrc: pic3
        },
        {
            imageSrc: pic4,
            alt: 'pic4',
            title: 'pic4',
            thumbnailImageSrc: pic4
        },
        {
            imageSrc: pic5,
            alt: 'pic5',
            title: 'pic5',
            thumbnailImageSrc: pic5
        },
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{Parse(detail.topic)}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                </div>
            </div>
        </div>
    );
}

export default Id2;
