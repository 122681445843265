import React from 'react';
import '../projects-pages.css'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'

const Discover = () => {

    const detail = 
        {
            id:10,
            imgSrc:cover,
            ini:'DISCOVER Disentangling the origins of SARS-CoV-2',
            topic:'',
            inform:`The main aim of the project is to monitor the origin of SARS-CoV2 in natural environments sharing biogeographical and socio-ecological characteristics with south-west China (regions of northern Thailand and Lao PDR). The aim is to characterise the natural cycle of SARS-CoV2 and how it emerges in humans. The zoonotic/emergence risk of Sarbecoviruses linked to SARS-CoV2 infecting wild animals in northern Southeast Asia will be estimated using a model that integrates phylodynamic data/analyses with socio-ecological factors, in order to develop genuine strategies for anticipating and preventing future emergences.`
        }
    

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
        }
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{detail.topic}</h2>
                <p>{detail.inform}</p>
                <div className="btn">
                    
                </div>
            </div>
        </div>
    );
}

export default Discover;
