import React from 'react';
import '../projects-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpg'
import pic3 from './pic3.jpg'
import pic4 from './pic4.jpg'
import pic5 from './pic5.JPG'

const Ciren = () => {

    const detail = {
        id:8,
        imgSrc: `${process.env.PUBLIC_URL}/research/CIREN/cover.jpg`,
        ini:'CIREN',
        topic:`Centre International de Recherche et d’Éducation environnementales de Nakaï`,
        inform:`LMI PRESTO is the project manager for this scientific research facility on the outskirts of the Nam Theun 2 hydroelectric dam in central Laos. This new infrastructure is being set up by the Nam Theun 2 Power Company (<b>NTPC</b>), the company that manages the dam, in close collaboration with the<b>IRD</b>, the University of Toulouse and several foundations. It includes an observatory for the M-tropics Critical Zone Observatory Network (<a>https://mtropics.obs-mip.fr/</a>), which feeds IPCC data, an applied research centre and an education centre to raise awareness of climate change and biodiversity conservation among young people.
It has received funding of €155k for its construction and funding has been sought from the AUF to set up training courses.
2 Lorasat training courses were held. The aim was to introduce inexpensive techniques for studying and monitoring soil components in real time. In a global context of global change and the need for increased environmental monitoring, there is an urgent need for real-time transmission of the information and data collected so that we can better anticipate risk situations (flooding, spread of disease, etc.) or situations requiring immediate action (switching on an irrigation system, switching on a fan in stables that are too hot, etc.). Generally transmitted to Internet gateways and servers using GSM or WiFi connections, Laos has many remote areas where these technologies cannot be used.
In this situation, the new LPWAN (low power wide area network) communication technologies offer an encouraging alternative, as they are less costly and require less energy to reach the satellites. This is particularly true of the LoRa satellite and its LoRaWAN telecommunications protocol.
A training course on the detection of pathogenic nematodes is scheduled for October 2024, in collaboration with the University of Brno (Czech Republic).
`,
        link1:`https://www.ird.fr/au-ciren-derniere-session-de-la-formation-lorasat-sur-le-deploiement-des-applications-de`,
        link2:`https://en.ird.fr/final-session-cirens-lorasat-training-course-deployment-environmental-monitoring-applications`,
        link3:`https://www.ird.fr/dernier-jour-de-latelier-lorasat-au-ciren`,
        link4:`https://en.ird.fr/last-day-lorasat-workshop-ciren`
    }

    const handleLink1 = () =>{
        window.open(detail.link1, '_blank', 'noreferrer')
    }
    const handleLink2 = () =>{
        window.open(detail.link2, '_blank', 'noreferrer')
    }
    const handleLink3 = () =>{
        window.open(detail.link3, '_blank', 'noreferrer')
    }
    const handleLink4 = () =>{
        window.open(detail.link4, '_blank', 'noreferrer')
    }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
        },
        {
            imageSrc: pic3,
            alt: 'pic3',
            title: 'pic3',
        },{
            imageSrc: pic4,
            alt: 'pic4',
            title: 'pic4',
        },{
            imageSrc: pic5,
            alt: 'pic5',
            title: 'pic5',
        }
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{detail.topic}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                    <button onClick={handleLink2}>{detail.link2}</button>
                    <button onClick={handleLink3}>{detail.link3}</button>
                    <button onClick={handleLink4}>{detail.link4}</button>
                </div>
            </div>
        </div>
    );
}

export default Ciren;
