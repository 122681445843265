import React from 'react';
import '../projects-pages.css'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpeg'
import pic3 from './pic3.jpeg'
import pic4 from './pic4.jpg'

const Onenakai = () => {

    const detail = 
        {
            id:2,
            imgSrc:`${process.env.PUBLIC_URL}/research/ONENAKAI/cover.jpg`,
            ini:'ONENAKAI',
            topic:'',
            inform:`A One-Health approach to estimate the prevalence and genetic diversity of circulating gastrointestinal pathogens among elephants, domesticated cattle and the human population living on the edge of Nakai-Nam Theun National Park, Laos
The aim of the ONENAKAI project was to conduct a pilot study on the Nakai Plateau and around the Nakai Reservoir in Laos, to investigate the presence, the genetic diversity and the circulation of gastro-intestinal parasites in humans, domesticated bovines and elephants, and to determine their potential role as reservoir hosts contributing to the environmental sustenance of zoonotic parasitic infections.
Faecal samples collection has been completed among these three categories, the 40 selected people, owners of domesticated cattle sharing the same territories as wild elephants have received a complete Health check-up and responded to the health questionnaire which is under analyses at the moment.  
All faecal samples have been analyzed for the presence of parasitic eggs in their stool by students at Lao TPHI under the supervision of Prof Somphou Sayasone. A Master student (Ms. Xaiyavong Phengsavanh) and a technician (Mr. Phonekeo Seng Aloun) from the LAOTPHI will start extracting the DNA from the samples collected before the end of March and will subsequently perform microsatellite analyses on wild elephants and domesticated cattle between the months of May and June to determine the number of effective individuals collected. We are finalizing at this very moment the multiplexing approach to address this together with our partners in Malaysia. 
Going beyond the microscopic analyses and adopt a molecular approach to determine the presence and the prevalence of pathogens in stool samples is new to the master students at LaoTPHI. 
During the month of July in collaboration with the Joint International Laboratory PRESTO (Protect-dEtect-STOp) an expert on Loop Mediated Isothermal Amplification (LAMP), Ms. Sayamon Hongjaisee, from Chiang Mai University, Thailand will join us to give a seminar on these procedure and target two pathogens to be analyzed from the stool samples collected. At the end of August, the student selected will be ready to defend her Master Thesis.  In addition, this project, through the set-up of collaborations and validation of research permits in the Khamounane region and within the Nam Theun National Park has allowed us to plant a seed for future collaborations on the circulation of gastrointestinal parasites, which encompasses animal and human Health.
Part of the extracted DNA is going to be sent in April to the faculty of Veterinary medicine at the University of Brno, In the Czech Republic where the PI had established a collaboration in the past working on parasites in great apes of Africa. Under the supervision of Barbora Pafco and Klara Petrzelkova, the samples collected are going to be run with a next generation sequencing approach to compare the circulating strongyles diversity in the three populations categories.  Funding permitting, it is our intention to extend the sampling to a broader area in the Nakai Plateau region. 
`,
            link1:`https://ohsea.ird.fr/en/onenakai/`
        }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
        },
        {
            imageSrc: pic3,
            alt: 'pic3',
            title: 'pic3',
        },{
            imageSrc: pic4,
            alt: 'pic4',
            title: 'pic4',
        }
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{detail.topic}</h2>
                <p>{detail.inform}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                </div>
            </div>
        </div>
    );
}

export default Onenakai;
