import React from 'react';
import '../projects-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.png'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpg'
import pic3 from './pic3.jpg'
import pic4 from './pic4.jpg'
import pic5 from './pic5.jpg'


const Page = () => {
   
    const detail = 
        {
            id:5,
            imgSrc:cover,
            ini:'SIREN',
            topic:'Survey of zoonotic viruses in wildlife animal and human contracts in Chiang Mai province',
            inform:`The majority of emerging pathogens originates in wildlife reservoirs. Transmission to humans occurs particularly in areas where there are close contacts between human and wildlife animals. Southeast Asia (SEA) is a global hotspot ofbiodiversity; 20% of global plant, animal and marine species are found in this region. However, climate change and loss of biodiversity related to human activities and behaviours such as continuing deforestation, land use change, agricultural expansion and intensification, expansion of human settlements, wildlife trade and hunting have contributed to increasing the risk of spillover events, i.e. transmission of a pathogen from a reservoir population to a novel host population. In recent decades, numerous viral zoonoses (i.e. Nipah/Hindra virus infection, H1N1 Swine flu, SARS, COVID-19) have emerged there. Chiang Mai Province is a potential area for zoonotic viral emergence due to its large biodiversity and taking advantage of the forest and wildlife by rural communities such as hunting wildlife for consumption. Large volumes of wildlife animals are hunted to supply restaurants and families. Poor sanitary conditions during food preparation create ideal conditions for spillover events, likely similar to the emergence of SARS-CoV-2 in the human population. Areas where multiple interactions occur between humans and wildlife animals are potential hot spots for zoonotic pathogen transmission. Thus conducting a survey of zoonotic viruses in humans and animals in those areas as well as in rural forest communities may identify spillover events. In addition, analysis of socio-behavior factors will help better understand the risk of transmission to humans and between humans and identify training needs to prevent their transmission in the communities.`,
            link1:`https://lucent.ams.cmu.ac.th/siren.php`,
          }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
        },
        {
            imageSrc: pic3,
            alt: 'pic3',
            title: 'pic3',
        },
        {
            imageSrc: pic4,
            alt: 'pic4',
            title: 'pic4',
        },
        {
            imageSrc: pic5,
            alt: 'pic5',
            title: 'pic5',
        }
    ]

    return (
        <div className='elements containers'>
           <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{Parse(detail.topic)}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                </div>
            </div>
        </div>
    );
}

export default Page;
