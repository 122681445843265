import React from 'react';
import '../../news-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpg'

const Page = () => {

    const detail = 
        {
            id:1,
            imgSrc:cover,
            ini:'Poster presentation in "SEAOHUN 2024 International Conference in Chiang Mai, Thailand"',
            href:'',
            element:'',
            topic:'',
            inform:`On September 19, 2024, Ms. Premmarin Inmonthian, a master's degree student of Assistant Professor Dr. Wuttichai Kamduang in the International Program of Medical Technology at Chiang Mai University, presented her research in a poster presentation titled "Identification of Respiratory Viruses in Wildlife Contacts: A Study from Omkoi District, Thailand." This took place during the SEAOHUN 2024 International Conference in Chiang Mai, Thailand, under the theme "One Health in Action," held at the Shangri-La Hotel, Chiang Mai. `
        ,
            link1:`https://www.facebook.com/permalink.php?story_fbid=pfbid0319wsdLoWc89JRcYrxAvEuZsJdGf3urLsmTtqH38tMDLQKiDtuF53Afhppibkmii7l&id=100089824854973`,
        }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
            thumbnailImageSrc: cover
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
            thumbnailImageSrc: pic1
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
            thumbnailImageSrc: pic2
        },
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{Parse(detail.topic)}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                </div>
            </div>
        </div>
    );
}

export default Page;
