import { Handle, Position } from '@xyflow/react';


function TextUpdaterNode({ data, isConnectable }) {

  return (
    <div className="text-updater-node">
      <Handle
        type="source"
        id='top'
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id='right'
        position={Position.Right}
        isConnectable={isConnectable}
      />
      <div className='container'>
        <div className="items">
            <h1>{data.value}</h1>
        </div>
      </div>
      <Handle
        type="source"
        id='bottom'
        position={Position.Bottom}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id='left'
        position={Position.Left}
        isConnectable={isConnectable}
      />
    </div>

    
  );
}

export default TextUpdaterNode;
