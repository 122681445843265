import React, { useEffect, useState } from 'react'
import './css/publication.css'

import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator'

import { Outlet, Route, Routes } from 'react-router-dom'

import ErrorPage from './error'

const Publications = () => {
  const handleUp = () =>{
    window.scrollTo({top:0})
  }

    const [active, setActive] = useState('')
    const handlePopup = (e) =>{
        const key = e.currentTarget.getAttribute('data-key')
        setActive(prevActive => prevActive === key ? '' : key)
    }

    useEffect(()=>{
        const popUp = () =>{
            if(active){
                const elements = document.querySelectorAll(`.containers ${active}`)
                elements.forEach(element =>{
                    element.classList.toggle('active')
                })
            }else{
                return
            }
        }
        popUp()
    },[active])

    // FILTER Catalogs
    const [filterInput, setFilterInput] = useState('')
    const [filteredData, setFilterData] = useState([])

    const [search, setSearch] = useState('')
    const handleSearch = (e) =>{
        const value = e.target.value
        setFilterInput(value)
        setSearch(value)

        const filtered = publications.filter((publication)=>
          publication.topic.toLowerCase().includes(value.toLowerCase()) ||
          publication.author.toLowerCase().includes(value.toLowerCase())
        )
        setFilterData(filtered)
    }

    const [Year, setYear] = useState(false)
    const handleYear = (e) =>{
        const value = e.target.value
        setYear(value)
        setFilterInput(value)

        const filtered = publications.filter((publication) =>
          publication.year.toString().includes(value.toString())
        )
        setFilterData(filtered)
    }

    const years = [
        {
            id: 0,
            year: false,
            label: 'All'
        },
        {
            id:4,
            year: 2024,
            label: 2024
        },
        {
            id:3,
            year: 2023,
            label: 2023
        },
        {
            id: 2,
            year: 2022,
            label: 2022
        },
        {
            id: 1,
            year: 2021,
            label: 2021
        },
    ]

    const publications = [
        {
            id: 9,
            topic: 'Seroprevalence of SARS-CoV-2 nucleocapsid antibody among sex workers during the 5th epidemic wave with Omicron variant in Chiang Mai, Thailand',
            author: 'Sayamon Hongjaisee, Ratchadakorn Guntala, Arunrat Tangmunkongvorakul , Nicole Ngo-Giang-Huong and Woottichai Khamduang',
            publish: 'Published: August 2024',
            year: 2024,
            book: 'Heliyon Volume 10, Issue 17;https://doi.org/10.1016/j.heliyon.2024.e36807',
            link: 'https://www.sciencedirect.com/science/article/pii/S2405844024128381?via%3Dihub'
        },
        {
            id: 8,
            topic: 'Contamination of Streptococcus suis and S. suis Serotype 2 in Raw Pork and Edible Pig Organs: A Public Health Concern in Chiang Mai, Thailand',
            author: 'Ratchadakorn Guntala, Likhitphorn Khamai, Nattawara Srisai, Sakaewan Ounjaijean, Woottichai Khamduang and Sayamon Hongjaisee',
            publish: 'Published: July 2024',
            year: 2024,
            book: 'Foods 2024, 13(13), 2119;https://doi.org/10.3390/foods13132119 (registering DOI)',
            link: 'https://www.mdpi.com/2304-8158/13/13/2119?fbclid=IwZXh0bgNhZW0CMTAAAR12MbTsGzvoCBZkZD1SpjYkCBADydlwa9t1KeZZXdbJiDauOxYlh-AQHTk_aem_PxnmswyJIBY7p35guqmpEg'
        },
        {
            id: 7,
            topic: 'A Single-Tube Colorimetric Loop-Mediated Isothermal Amplification for Rapid Detection of SARS-CoV-2 RNA',
            author: 'Hongjaisee S, Kham-Kjing N, Musikul P, Daengkaokhew W, Kongson N, Guntala R, Jaiyapan N, Kline E, Panpradist N, Ngo-Giang-Huong N, et al.',
            publish: 'Published: September 2023',
            year: 2023,
            book: 'Diagnostics 2023, 13(19), 3040;https://doi.org/10.3390/diagnostics13193040',
            link: 'https://www.mdpi.com/2075-4418/13/19/3040'
        },
        {
            id: 6,
            topic: 'Antibody response to rabies pre-exposure vaccination among village health volunteers in a northern region of Thailand',
            author: 'Khamduang W, Hongjaisee S, Pothita T, Kongyai N, Surajinda S, Petsophonsakul W, Pornprasert S.',
            publish: 'Published: February 2022',
            year: 2022,
            book: 'Preventive veterinary medicine, 199, 105555. https://doi.org/10.1016/j.prevetmed.2021.105555',
            link: 'https://www.sciencedirect.com/science/article/abs/pii/S0167587721002993?via%3Dihub'
            
        },
        {
            id: 5,
            topic: 'Prevalences of SARS-CoV-2 RNA and anti-SARS-CoV-2 among at-risk populations in Chiang Mai and Lamphun provinces, Thailand, during November 2020-January 2021',
            author: 'Takalay S, Ngo-Giang-Huong N, Kongnim W, Mongkolwat P, Phoseng P, Wangsaeng N, Hongjaisee S, Butr-Indr B, Tragoolpua K, Jourdain G, Pornprasert S, Khamduang W.',
            publish: 'Published: February 2022',
            year: 2022,
            book: 'PloS one, 17(2), e0263127. https://doi.org/10.1371/journal.pone.0263127',
            link: 'https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0263127'
            
        },
        {
            id: 4,
            topic: 'Zika Virus Immunoglobulin G Seroprevalence among Young Adults Living with HIV or without HIV in Thailand from 1997 to 2017',
            author: 'Choyrum S, Wangsaeng N, Nechba A, Salvadori N, Saisom R, Achalapong J, Putiyanun C, Sabsanong P, Sangsawang S, Patamasingh Na Ayudhaya O, Jourdain G, Ngo-Giang-Huong N, Khamduang W.',
            publish: 'Published: February 2022',
            year: 2022,
            book: 'Viruses, 14(2), 368. https://doi.org/10.3390/v14020368',
            link: 'https://www.mdpi.com/1999-4915/14/2/368'
            
        },
        {
            id: 3,
            topic: 'Lack of Association between Adverse Pregnancy Outcomes and Zika Antibodies among Pregnant Women in Thailand between 1997 and 2015',
            author: 'Ngo-Giang-Huong N, Leroi C, Fusco D, Cressey TR, Wangsaeng N, Salvadori N, Kongyai N, Sirirungsi W, Lallemant M, Auewarakul P, Khamduang W, Jourdain G.',
            publish: 'Published: July 2021',
            year: 2021,
            book: 'Viruses, 13(8), 1423. https://doi.org/10.3390/v13081423',
            link: 'https://www.mdpi.com/1999-4915/13/8/1423'
            
        },
        {
            id: 2,
            topic: 'Soil-transmitted helminth infections in free-ranging non-human primates from Cameroon and Gabon',
            author: 'Sirima, C., Bizet, C., Hamou, H., Červená, B., Lemarcis, T., Esteban, A., Peeters, M., Mpoudi Ngole, E., Mombo, I. M., Liégeois, F., Petrželková, K. J., Boussinesq, M., & Locatelli, S.',
            publish: 'Published: July 2021',
            year: 2021,
            book: 'Parasites & vectors, 14(1), 354. https://doi.org/10.1186/s13071-021-04855-7',
            link: 'https://pubmed.ncbi.nlm.nih.gov/34225777/'
            
        },
        {
            id: 1,
            topic: 'Limitations of PCR detection of filarial DNA in human stools from subjects non-infected with soil-transmitted helminths',
            author: 'Maxime P. M. Doret, Hugues C. Nana-Djeunga, Narcisse Nzune-Toche, Sébastien D. S. Pion, Cédric B. Chesnais, Michel Boussinesq, Joseph Kamgno, Emmanuelle Varlet-Marie and Sabrina Locatelli',
            publish: 'Published: May 2021',
            year: 2021,
            book: 'Parasite, 28 (2021) 47. https://doi.org/10.1051/parasite/2021046',
            link: 'https://doi.org/10.1051/parasite/2021046'
            
        },
    ]

    // Maximum containers
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerpage, setRowPerPage] = useState(5)


    // calculate data display
    const paginatedData = publications.slice(currentPage * rowsPerpage, (currentPage + 1) * rowsPerpage)
    // Handle page change
    const onPageChange = (e) => {
        setCurrentPage(e.page)
        setRowPerPage(e.rows)
        handleUp()
    }

    const publicationRoutes = publications.map((publication) => (
        <Route path={publication.href} element={<publication.element />}/>
    ))
    const dataToDisplay = filterInput ? filteredData : paginatedData
    const totalRecords = filterInput ? filteredData.length : publications.length

    return(
        <div className="publications">
            <div className="people-modal" style={{display: active? 'block' : 'none'}} onClick={handlePopup}></div>
            <div className="cover">
                <div className="cover-bg"></div>
                <div className="cover-text flyIn">
                    <h1>PUBLICATIONS</h1>
                </div>
            </div>

            {/* Search input */}
            <div className="filter">
                <div className="items search">
                    <div className="head">
                        <b>Search: </b>
                    </div>
                    <div className="search">
                        <InputText type="text" placeholder="enter search term..." onChange={handleSearch} value={search} disabled={ Year !== false}/>
                        <i className="pi pi-search"></i>
                    </div>
                </div>

               <div className="dropdown">
                {/* FILTER by YEAR */}
                    <div className="items dropdown year">
                        <div className="head">
                            <b>Year: </b>
                        </div>
                        <div className="year">
                            <Dropdown value={Year} onChange={handleYear} options={years} optionLabel='label' optionValue='year' placeholder='All' disabled={search !== '' }/>
                        </div>
                    </div>
               </div>
            </div>

            <Routes>
                <Route index element={
                  <div className="publications">
                    {dataToDisplay.map((publication) => (
                      <div className={`containers ${publication.id}`} key={publication.id}>
                        <a className='items' href={publication.link} target='_blank' rel='noreferrer'>
                          <div className="topic"><h3>{publication.topic}</h3></div>
                          <div className="authors"><h4 style={{fontStyle: 'italic', color: '#B7B7B7'}}>{publication.author}</h4></div>
                            <div className="details">
                              <div className="publish"><h5>{publication.publish}</h5></div>
                              <div className="book"><h5>{publication.book}</h5></div>
                            </div>
                        </a>
                      </div>
                    ))}
                    
                  </div>
                }/>
                {publicationRoutes}
                <Route path='*' element={<ErrorPage/>} />
            </Routes>
            <Outlet />  

            <div className="paginator">
                <Paginator
                    first={currentPage * rowsPerpage}
                    rows={rowsPerpage}
                    totalRecords={totalRecords}
                    onPageChange={onPageChange}
                />
            </div>
        </div>
    )
}

export default Publications