import React from 'react';
import '../../news-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpg'
import pic3 from './pic3.jpg'
import pic4 from './pic4.jpg'
import pic5 from './pic5.jpg'
import pic6 from './pic6.jpg'


const Id2 = () => {

    const detail = 
        {
            id:2,
            imgSrc:cover,
            ini:'Boung Phao Food Festival (Laos)',
            href:'',
            element:'',
            topic:'',
            inform:`Organised at the Ban Boung Phao health centre - one of the sites where the NutriLao study took place - the festival brought together local villagers as well as representatives from the village and district authorities, the Agence universitaire de la Francophonie (AUF), the University of Health Sciences, the World Food Programme and the Pierre Fabre Foundation. The aim of the event was to raise awareness of nutrition in general, as well as the prevention of orally-transmitted diseases such as opistorchiasis caused by eating raw fish`
        ,
            link1:`https://www.ird.fr/un-clap-de-fin-tout-en-saveurs-pour-les-projets-immana-et-nutrilao`,
            link2:`https://en.ird.fr/end-immana-and-nutrilao-projects-colourful-and-tasty-finale`
        }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }
        const handleLink2 = () =>{
            window.open(detail.link2, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
            thumbnailImageSrc: cover
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
            thumbnailImageSrc: pic1
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
            thumbnailImageSrc: pic2
        },
        {
            imageSrc: pic3,
            alt: 'pic3',
            title: 'pic3',
            thumbnailImageSrc: pic3
        },
        {
            imageSrc: pic4,
            alt: 'pic4',
            title: 'pic4',
            thumbnailImageSrc: pic4
        },
        {
            imageSrc: pic5,
            alt: 'pic5',
            title: 'pic5',
            thumbnailImageSrc: pic5
        },
        {
            imageSrc: pic6,
            alt: 'pic6',
            title: 'pic6',
            thumbnailImageSrc: pic6
        }
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{Parse(detail.topic)}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                    <button onClick={handleLink2}>{detail.link2}</button>
                </div>
            </div>
        </div>
    );
}

export default Id2;
