import React from 'react';
import '../projects-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpg'

const Inedi = () => {

    const detail = 
        {
            id:4,
            imgSrc:`${process.env.PUBLIC_URL}/research/INEDI/cover.jpg`,
            ini:'INEDI',
            topic:'',
            inform:`In a context of accelerating urban expansion, major changes in land use patterns in Vientiane, the transdisciplinary approach - combining environmental sciences (ecology, biology), social sciences (urban geography) and urbanism – of this project aims to 
            provide a global perspective on waste management issue at a landfill scale. 
            A spotlight on the tight spatial relationships between ecosystems, human activities 
            (including urban planning) and health will be carried out through the implementation of 
            various measures and analyses in order to establish a diagnosis of the extent of pollution 
            and health risks and to prevent a zoonotic disease emergence; to raise awareness of the 
            need to advocate for the One Health Approach in urban areas with the decision-makers and 
            the public at large.The methodology of the research, in particular for all the activities 
            related to social sciences will be elaborated jointly with the Vietnamese team
            (Hanoi Architectural University) which will share its experience in the spatial and 
            social data collection and treatment.The aim of this project was to carry out surveys 
            among <b>waste pickers at the Vientiane landfill site, in order to estimate the level of</b>
            nuisance suffered by its collectors and residents living on the edge of the landfill.
The researchers were particularly interested in the impact on their daily lives (food, home economics, ....) and <b>health conditions</b> since the landfill was set up in 2008. The research methodology and all the team's dissemination activities in Laos were carried out jointly with a Vietnamese team from the Hanoi University of Architecture, whose expertise is recognised in the collection of recyclable waste and the definition of the informal sector.
The main results of this survey were presented at a seminar held at the Institut français du Laos in April 2023, illustrated by a photographic exhibition in partnership with the photographer Nicholas Bosoni.
`,
            link1:`https://ohsea.ird.fr/en/inedi/?doing_wp_cron=1720424976.2585949897766113281250`,
            link2:`https://en.ird.fr/sustainable-city-science-day-conference-and-exhibitions-national-university-laos`,
            link3:`https://en.ird.fr/vietnam/projects`
       }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }
        const handleLink2 = () =>{
            window.open(detail.link2, '_blank', 'noreferrer')
        }
        const handleLink3 = () =>{
            window.open(detail.link3, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
        }
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{detail.topic}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                    <button onClick={handleLink2}>{detail.link2}</button>
                    <button onClick={handleLink3}>{detail.link3}</button>
                </div>
            </div>
        </div>
    );
}

export default Inedi;
