import React from 'react';
import '../projects-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpg'

const Rocket = () => {
   
    const detail = 
        {
            id:5,
            imgSrc:cover,
            ini:'RoCket Research on Crickets',
            topic:'Low-cost/high-tech production of edible crickets for sustainable agriculture and women empowerment in Laos. <br>(A Private /Public South North Consortium)',
            inform:`The need for new tools to speed up development remains at a high level, in order to <b>generate sources of income for women but also for vulnerable population such as disabled people, elders, and landless farmers.</b>
In this regard, the development of <b>a sustainable edible cricket value chain</b> could offer a great opportunity to address this challenge.
<b>These insects are an important source of protein, requiring a very low investment for a significant return, with almost no waste. In addition, the use of inexpensive sensors to remotely monitor their growth can significantly increase their production yields. Finally, the extraction of high value -added products from their cuticle can also provide producers with a significant income.</b>
The aim of this project is to supports the implementation of cricket breeding activity that is a sustainable activity for local women by providing a perennial income through a farmer's contract with Cricket Lao Farm.
The Project can be categorized as an Integrated Conservation and Development Project.
Its conceptual framework implies the assumption that biodiversity conservation outcomes are inextricably
linked to rural development. We consider that:
<ul>
    <li>Alleviating poverty increases the interest and capacity for natural resource conservation (sustainable economic development approach),</li>
    <li>Offering a new source of regular income can reduce the impact on natural resources gathering
(alternative livelihood approach),</li>
    <li>Involving local people in the planning and management of natural resources and in the sharing of benefits leads to better livelihood (Participatory planning approach).</li>
</ul>
At the end, it will provide vulnerable families with a combination of asset management support, productive assets and coaching to strengthen their economic security and resilience link to forest conservation.
Insect farming is a promising way to increase incomes.
The activity is adapted to the local context of targeted Households, and promotes social inclusion for women or persons with disability with limited resources (low labour intensive, no land required). 
`,
            link1:`https://youtu.be/_dob3RdZK-E`,
            link2:`https://en.ird.fr/no-skipping-meals-crickets`,
            link3:`https://en.ird.fr/rocket-project-enters-its-5th-production-cycle`,
            link4:`https://www.ird.fr/les-ecoliers-du-lycee-francais-international-de-vientiane-lfiv-visitent-la-ferme-de-grillons-du`
        }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }
        const handleLink2 = () =>{
            window.open(detail.link2, '_blank', 'noreferrer')
        }
        const handleLink3 = () =>{
            window.open(detail.link3, '_blank', 'noreferrer')
        }
        const handleLink4 = () =>{
            window.open(detail.link4, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
        }
    ]

    return (
        <div className='elements containers'>
           <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{Parse(detail.topic)}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                    <button onClick={handleLink2}>{detail.link2}</button>
                    <button onClick={handleLink3}>{detail.link3}</button>
                    <button onClick={handleLink4}>{detail.link4}</button>
                </div>
            </div>
        </div>
    );
}

export default Rocket;
