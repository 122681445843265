import { Outlet, Link } from "react-router-dom";
import { React, useState, useEffect } from "react";
import { Menubar } from 'primereact/menubar'
// import { InputText } from "primereact/inputtext"

import prestologocolor from '../logo/presto-logo-color.png'

import 'primeicons/primeicons.css'

import './css/layout.css'

const Layout = () =>{

    const [mobile, setMobile] = useState(window.innerWidth <= 1200)
    const [hamburger, setHamburger] = useState(window.innerWidth <= 1200)

      // Update mobile state when window resizes
    useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 1200);
      setHamburger(window.innerWidth <= 1200)
    };
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Hamburger toggle button
    const handleHamburger = (e) =>{
        setHamburger(!hamburger)
    }

    // Up button
    const [visible, setVisible] = useState(false)
    useEffect(()=>{
        const handleScroll = () =>{
            if(window.scrollY >= 300){
                setVisible(true)
            }else{
                setVisible(false)
            }
        }
        window.addEventListener('scroll', handleScroll)
        return ()=>{
            window.removeEventListener('scroll', handleScroll)
        }
    },[])
    const handleUp = () =>{
        window.scrollTo({top:0, behavior:'smooth'})
    }

    // const inputRef = useRef(null)
    // const setInputRef = (e) =>{
    //     if(e){
    //         e.focus()
    //     }
    //     inputRef.current = e
    // }

    // Nav fixed
    useEffect(()=>{
        const handleScreenY = () => {
            const navElements = document.querySelectorAll('.nav');
            const marginValue = window.scrollY >= 10 ? '-3.5rem' : '0rem';
            const position = window.scrollY >= 10 ? 'fixed' : 'relative'
            navElements.forEach((element) => {
                element.style.marginTop = marginValue;
                element.style.position = position
            });
        };
        window.addEventListener('scroll', handleScreenY);
        return () =>{
            window.removeEventListener('scroll', handleScreenY);
        }
    },[])

    // ALL layout & DROPDOWN
        const items = [
        {
            label: 'HOME',
            icon:'',
            template: () =>{
                return (
                    <Link id="item" to="/">HOME</Link>
                )
            }
        },
        {
            label:'ABOUT US',
            icon:'',
            template: () =>{
                return (
                    <Link id="item" to="aboutus">ABOUT US</Link>
                )
            }
        },
        {
            label:'PROJECTS',
            icon:'',
            template: () =>{
                return (
                    <Link id="item" to="projects">PROJECTS</Link>
                )
            }

        },
        {
            label:'PLATFORMS',
            icon:'',
            template: () =>{
                return (
                    <Link id="item" to="platforms">PLATFORMS</Link>
                )
            }
        },
        {
            label:'NEWS & ACTIVITIES',
            icon:'',
            template: () =>{
                return (
                    <Link id="item" to="news">NEWS & ACTIVITIES</Link>
                )
            }
        },
        {
            label: 'PUBLICATIONS',
            icon:'',
            template: () =>{
                return(
                    <Link id="item" to="publications">PUBLICATIONS</Link>
                )
            }
        },
        {
            label:'HOME',
            icon:'',
            template: () =>{
                return (
                <Link id="item" to="/" style={{display: !mobile? "none" : "block"}}>
                    <img src={prestologocolor} id="nav-logo" alt="home" />
                </Link>
                )
            }
        },
    ]

    return(
        <div className="navi container">
            <div className="modal" style={!hamburger ? {display: 'block'} : {display: 'none'}} onClick={() => handleHamburger()}></div>
            <div className="arrow" style={{display: visible? 'block':'none'}} onClick={handleUp}><i className="pi pi-arrow-up"></i></div>
            <div className="nav">
                {/* <Link id="item" to="/">
                    <img src={prestologocolor} id="nav-logo" alt="home" />
                </Link> */}
                <div className='cards'>
                    <Menubar style={{display: !hamburger ? 'block' : 'none'}} model={items}/>
                </div>

                <button className="toggle" style={!mobile ? {display:'none'} : {}} onClick={() => handleHamburger()}><i className={hamburger ? 'pi pi-bars' : 'pi pi-times'}></i></button>
            </div>
            <Outlet/>
        </div>
    )
}

export default Layout