import './css/home.css'
// import prestologo from '../logo/presto-logo-color.png'

// News
import News1 from './news/news/First parasitology training course on nematodes at CIREN/cover.jpg'
import News1Page from './news/news/First parasitology training course on nematodes at CIREN/index'
import News2 from './news/news/parasite_MEDCMU_241211/cover.jpg'
import News2Page from './news/news/parasite_MEDCMU_241211/index'
import News3 from './news/news/Lunch Seminar_241209/cover.jpg'
import News3Page from './news/news/Lunch Seminar_241209/index'
import News4 from './news/news/The 14th International Public Health Conference among Greater Mekong Sub-regional Countries/cover.jpg'
import News4Page from './news/news/The 14th International Public Health Conference among Greater Mekong Sub-regional Countries/index'
import News5 from './news/news/Poster presentation in SEAOHUN 2024 International Conference in Chiang Mai Thailand/cover.jpg'
import News5Page from './news/news/Poster presentation in SEAOHUN 2024 International Conference in Chiang Mai Thailand/index'
import News6 from './news/training/Disease Ecology Symposium and Training 28 Nov - 2 Dec 2022/cover.jpg'
import News6Page from './news/training/Disease Ecology Symposium and Training 28 Nov - 2 Dec 2022/index'

import { FaShield } from 'react-icons/fa6'
import { AiOutlineAim } from 'react-icons/ai'
import { AiOutlineStop } from 'react-icons/ai'
import { FaArrowRightLong } from "react-icons/fa6";

import { useEffect, useState } from 'react'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { Link, Route, Routes } from 'react-router-dom'

const Home = () => {

    const [scrolled, setScrolled] = useState(false);
    useEffect(()=>{
        const handleScroll = () =>{
            if(window.scrollY > 230){
                setScrolled(true)
            }
        };
        window.addEventListener('scroll', handleScroll)
        return () =>{
            window.removeEventListener('scroll', handleScroll)
        }
    },[])

    // NEWS
    const news = [
        {
            id:1,
            topic:'First parasitology training course on nematodes at CIREN',
            detail: `As part of IJL PRESTO activities, a parasitology workshop at CIREN (Nakaï, Laos) brought together 14 participants to learn about non-invasive sample collection and laboratory analysis of parasites with zoonotic transmission potential, with a specific focus on nematodes.
Implemented with the financial support of the Agence universitaire de la Francophonie in Laos, the workshop was developed and supervised by Dr 
Jana Kacmarikova and Dr Bethan Mason from the Department of Pathology and Parasitology at the University of Veterinary Sciences in Brno (Czech Republic) and 
by Sabrina Locatelli (UMR MIVEGEC, IRD). It brought together Laotian Master's level students and veterinarians to train them in parasitology techniques, and more 
specifically in transmission at the interface between wildlife, domestic animals and humans, with nematodes (nematode infections in humans and animals include 
ascariasis, trichuriasis, hookworm, enterobiasis, strongyloidiasis, filariasis, and trichinosis) as a practical case study. All participants contributed to the 
collection of human and animal faecal samples in Nakai National Park and in the villages of Nahou, Navang and Thameuang. For pedagogical purposes, additional samples from the Lao Conservation Trust for Wildlife (LCTW) were used, covering a wide range of wildlife species currently in care at the sanctuary (langurs, bears, palm civets, deer, turtles and gibbons). Back at the laboratory, participants were taught various methods of coproscopic analysis for the identification of gastrointestinal parasites, including flotation, sedimentation, larvoscopy and quantification techniques. At the same time, the students were given theoretical and practical training in veterinary ethnopharmacy by Dr Jean-Marc Dubost (Muséum National d'Histoire Naturelle, Paris), invited by the French Embassy in Laos.`,
            imgSrc:News1,
            element:News1Page,
            cat:'news',
            link: `First parasitology training course on nematodes at CIREN`,
        },
        {
            id:2,
            topic:'Explore oppotunities and strategies for improving education and research with Department of Parasitology, Faculty of Medicine, Chiang Mai University',
            detail: `On December 11, 2024, Dr. Dorothée Missé, Dr.Dorothée Missé co-heads the “Biology of Viral Infection” Department at IRD MIVEGEC Unit, France
            met with the Department of Parasitology, Faculty of Medicine, Chiang Mai University, to explore opportunities and strategies for developing education and research collaboration.`,
            imgSrc:News2,
            element:News2Page,
            cat:'news',
            link: `parasite_MEDCMU_241211`,
        },
        {
            id: 3,
            topic: 'LUNCH SEMINAR in the topic of "ARBOVIRUS TRANSMISSION AND PATHOGENESIS"',
            detail: 'Dr Dorothée Missé co-heads the “Biology of Viral Infection” Department at IRD MIVEGEC Unit. She works on the pathogenesis of emerging and re-emerging viruses (Dengue, Chikungunya, Zika, Mayaro...viruses) with a focus on virus-host interactions and innate immunity and antiviral compound discovery. She is involved in various "One Health" projects in Africa  (Zimbabwe & Cameroon). Dr Missé is also a nominated member of the CBS2 Doctoral School office at the Montpellier University of Excellence.',
            imgSrc: News3,
            element: News3Page,
            cat: 'news',
            link: 'Lunch Seminar_241209'
        },
        {
            id: 4,
            topic: 'The 14th International Public Health Conference among Greater Mekong Sub-regional Countries.',
            detail: 'On June 27 - 29, 2024, Miss Premarin Inmontian, a Master degree student in the field of Medical Technology (International Program) at the Faculty of Medical Technology, Chiang Mai University, with Assistant Professor Dr. Woottichai Khamduang as her advisor, participated and presented research results in the form of a poster presentation on the topic ""Identification of Respiratory Viruses in Wildlife Contacts: A Study from Omkoi District, Thailand at the international academic conference “The 14th International Public Health Conference among Greater Mekong Sub-regional Countries.” The conference is theme was Meeting the Sustainable Development Goals through Improved Nutrition, Health Equity, and Climate Change Adaptation"" and was held at Pullman Luang Prabang Hotel, Luang Prabang, Lao People is Democratic Republic.',
            imgSrc: News4,
            element: News4Page,
            cat: 'news',
            link: 'The 14th International Public Health Conference among Greater Mekong Sub-regional Countries'
        },
        {
            id: 5,
            topic: 'Disease Ecology Symposium and Training: 28 Nov - 2 Dec 2022',
            detail: 'This program is designed for researchers, scientists, and post-graduate students interested in the fields of disease ecology, biodiversity or other related fields such as conservation medicine, veterinary epidemiology, zoology, parasitology and microbiology who are seeking field, laboratory and computational trainings in monitoring of biodiversity, wildlife population, wildlife-borne diseases, disease reservoirs and vectors in tropical area. At Tropical Disease Research Centre (TDRC), Faculty of Tropical Medicine, Mahidol University Kanchanaburi Campus (MUKA), Sai Yok, Kanchanaburi, Thailand.',
            imgSrc: News5,
            element: News5Page,
            cat: 'training',
            link: 'Disease Ecology Symposium and Training 28 Nov - 2 Dec 2022'
        },
        {
            id: 6,
            topic: 'Poster presentation in "SEAOHUN 2024 International Conference in Chiang Mai, Thailand"',
            detail: `On September 19, 2024, Ms. Premmarin Inmonthian, a master's degree student of Assistant Professor Dr. Wuttichai Kamduang in the International Program of Medical Technology at Chiang Mai University, presented her research in a poster presentation titled "Identification of Respiratory Viruses in Wildlife Contacts: A Study from Omkoi District, Thailand." This took place during the SEAOHUN 2024 International Conference in Chiang Mai, Thailand, under the theme "One Health in Action," held at the Shangri-La Hotel, Chiang Mai.`,
            imgSrc: News6,
            element: News6Page,
            cat: 'news',
            link: 'Poster presentation in SEAOHUN 2024 International Conference in Chiang Mai, Thailand'
        }
    ]

    const handleRoute = news.map((newss) => (
        <Route path={`news/${newss.cat}/${newss.link}`} element={<newss.element/>} key={newss.id}/>
    ))

    // PROJECTS
    // const projects = [
    //     {
    //         id: 1,
    //         shortname: 'Short1',
    //         fullname: 'full1',
    //         detail: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat sit dignissimos eum. Delectus illo repellendus cum tempora eligendi, voluptate dolor nihil, porro optio voluptates in architecto omnis a dicta odio?',
    //         link:'',
    //         imgSrc: prestologo
    //     },
    //     {
    //         id: 2,
    //         shortname: 'Short2',
    //         fullname: 'full1',
    //         detail: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat sit dignissimos eum. Delectus illo repellendus cum tempora eligendi, voluptate dolor nihil, porro optio voluptates in architecto omnis a dicta odio?',
    //         link:'',
    //         imgSrc: prestologo
    //     },
    //     {
    //         id: 3,
    //         shortname: 'Short3',
    //         fullname: 'full1',
    //         detail: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat sit dignissimos eum. Delectus illo repellendus cum tempora eligendi, voluptate dolor nihil, porro optio voluptates in architecto omnis a dicta odio?',
    //         link:'',
    //         imgSrc: prestologo
    //     }
    // ]

    return(
        <div className="homepage">
            <div className="cover">
                <div className="cover-bg"></div>
                <div className="cover-text flyIn">
                    <h1>To understand how zoonotic infections can (re)emerge, spread, and how they can be stopped</h1>
                </div>
            </div>

            <div className="objectives">
                <div className="containers">
                    <div className="img"><FaShield/></div>
                    <div className="head">
                        <h3><b>PR</b>otect</h3>
                    </div>
                    <div className="ul">
                        <ul>
                            <li>Biodiversity</li>
                            <li>Land and pristine territories</li>
                            <li>Vulnerable animals and human populations</li>
                        </ul>
                    </div>
                </div>
                <div className="containers">
                    <div className="img"><AiOutlineAim/></div>
                    <div className="head">
                        <h3>d<b>E</b>tect</h3>
                    </div>
                    <div className="ul">
                        <ul>
                            <li>Zoonosis emergence</li>
                            <li>Spillover</li>
                            <li>Spillback</li>
                        </ul>
                    </div>
                </div>
                <div className="containers">
                    <div className="img"><AiOutlineStop/></div>
                    <div className="head">
                        <h3><b>STO</b>p</h3>
                    </div>
                    <div className="ul">
                        <ul>
                            <li>High risk activities</li>
                            <li>Emerging infectious diseases outbreaks</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="aboutus">
                <h2><Link to='aboutus'>LEARN MORE ABOUT US<FaArrowRightLong/></Link></h2>
            </div>
<hr />
            <div className="news">
                <Routes>
                    <Route index element={
                        <OwlCarousel
                        className='owl-theme'             
                        margin={0}
                        lazyLoad={true}
                        loop
                        items={1}
                        autoplay={true}
                        autoplayTimeout={7000}
                        autoplayHoverPause={true}
                        smartSpeed={1500}
                        >
                            {news.map((newss)=> (
                                <div className={`containers ${newss.id}`} key={newss.id}>
                                    <div className="text">
                                        <h3>{newss.topic}</h3>
                                        <p>{newss.detail}</p>
                                        <div className="link">
                                            <Link to={`news/${newss.cat}/${newss.link}`}>SEE MORE</Link>
                                        </div>
                                    </div>
                                
                                    <div className="img">
                                        <img src={newss.imgSrc} alt='img' />
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    }/>
                    {handleRoute}
                </Routes>
            </div>
<hr />
            {/* <div className="projects">
                <div className="head">
                    <h2>PROJECTS</h2>
                </div>
                <div className="containers">
                    <OwlCarousel
                        className='owl-theme'             
                        margin={20}
                        lazyLoad={true}
                        loop
                        items={4}
                        autoplay={true}
                        autoplayTimeout={5000}
                        autoplayHoverPause={true}
                        smartSpeed={1500}
                        responsive={{
                            0: { items: 1 },
                            800: { items: 2 },
                            1200: { items: 4 }
                        }}
                    >
                        {projects.map((project) => (
                            <div className={`item ${project.id}`}>
                                <div className="img">
                                    <img src={project.imgSrc} alt="img" />
                                </div>
                                <div className="topic">
                                    <h3>{project.shortname}</h3>
                                </div>
                                <div className="fullname">
                                    <h4>{project.fullname}</h4>
                                </div>
                                <div className="detail">
                                    <p>{project.detail}</p>
                                </div>
                                <div className="btn">
                                    <a href={project.link}>SEE MORE</a>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
            </div> */}
        </div>
    )
}

export default Home