import React from 'react';
import '../../news-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import img1 from './img1.jpg'
import img2 from './img2.jpg'
import img3 from './img3.jpg'
import img4 from './img4.jpg'
import img5 from './img5.jpg'

const Page = () => {

    const detail = 
        {
            id:1,
            imgSrc:cover,
            ini:'First parasitology training course on nematodes at CIREN',
            href:'',
            element:'',
            topic:'As part of IJL PRESTO activities, a parasitology workshop at CIREN (Nakaï, Laos) brought together 14 participants to learn about non-invasive sample collection and laboratory analysis of parasites with zoonotic transmission potential, with a specific focus on nematodes.',
            inform: `Implemented with the financial support of the Agence universitaire de la Francophonie in Laos, the workshop was developed and supervised by Dr Jana Kacmarikova and Dr Bethan Mason from the Department of Pathology and Parasitology at the University of Veterinary Sciences in Brno (Czech Republic) and by Sabrina Locatelli (UMR MIVEGEC, IRD). It brought together Laotian Master's level students and veterinarians to train them in parasitology techniques, and more specifically in transmission at the interface between wildlife, domestic animals and humans, with nematodes (nematode infections in humans and animals include ascariasis, trichuriasis, hookworm, enterobiasis, strongyloidiasis, filariasis, and trichinosis) as a practical case study.
            All participants contributed to the collection of human and animal faecal samples in Nakai National Park and in the villages of Nahou, Navang and Thameuang. For pedagogical purposes, additional samples from the Lao Conservation Trust for Wildlife (LCTW) were used, covering a wide range of wildlife species currently in care at the sanctuary (langurs, bears, palm civets, deer, turtles and gibbons). Back at the laboratory, participants were taught various methods of coproscopic analysis for the identification of gastrointestinal parasites, including flotation, sedimentation, larvoscopy and quantification techniques. 
            At the same time, the students were given theoretical and practical training in veterinary ethnopharmacy by Dr Jean-Marc Dubost (Muséum National d'Histoire Naturelle, Paris), invited by the French Embassy in Laos.`,
            link1: `https://en.ird.fr/first-parasitology-training-course-nematodes-ciren`,
        }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
            thumbnailImageSrc: cover
        },
        {
            imageSrc: img1,
            alt: 'img1',
            title: 'img1',
            thumbnailImageSrc: img1
        },
        {
            imageSrc: img2,
            alt: 'img2',
            title: 'img2',
            thumbnailImageSrc: img2
        },
        {
            imageSrc: img3,
            alt: 'img3',
            title: 'img3',
            thumbnailImageSrc: img3
        },
        {
            imageSrc: img4,
            alt: 'img4',
            title: 'img4',
            thumbnailImageSrc: img4
        }, {
            imageSrc: img5,
            alt: 'img5',
            title: 'img5',
            thumbnailImageSrc: img5
        },
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{Parse(detail.topic)}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                </div>
            </div>
        </div>
    );
}

export default Page;
