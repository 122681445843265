import React, { useEffect, useState } from 'react'
import './css/projects.css'

import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator'

import { Link, Route, Routes } from 'react-router-dom'

// import img
import discover from './projects/DISCOVER/cover.jpg'
import lacoviss from './projects/Lacoviss/cover.jpg'
import ciren from './projects/CIREN/cover.jpg'
import immana from './projects/IMMANA/cover.jpg'
import nutri from './projects/Nutrilao/cover.jpg'
import rocket from './projects/ROCKET/cover.jpg'
import inedi from './projects/INEDI/cover.jpg'
import bilao from './projects/BILAO/cover.png'
import onenakai from './projects/ONENAKAI/cover.jpg'
import elaos from './projects/ELAOS/cover.jpg'
import verdi from './projects/VERDI/cover.png'
import siren from './projects/SIREN/cover.png'

// project elements
import Bilao from './projects/BILAO/bilao'
import Ciren from './projects/CIREN/ciren'
import Discover from './projects/DISCOVER/discover'
import Elaos from './projects/ELAOS/elaos'
import Immana from './projects/IMMANA/immana'
import Inedi from './projects/INEDI/inedi'
import Lacoviss from './projects/Lacoviss/lacoviss'
import Nutri from './projects/Nutrilao/nutrilao'
import Onenakai from './projects/ONENAKAI/onenakai'
import Rocket from './projects/ROCKET/rocket'
import Verdi from './projects/VERDI/index'
import Siren from './projects/SIREN/index'

import ErrorPage from './error'

const Projects = () => {
    const handleUp = () =>{
        window.scrollTo({top:0})
    }

    const [active, setActive] = useState('')
    const handlePopup = (e) =>{
        const key = e.currentTarget.getAttribute('data-key')
        setActive(prevActive => prevActive === key ? '' : key)
    }

    useEffect(()=>{
        const popUp = () =>{
            if(active){
                const elements = document.querySelectorAll(`.containers ${active}`)
                elements.forEach(element =>{
                    element.classList.toggle('active')
                })
            }else{
                return
            }
        }
        popUp()
    },[active])

    // FILTER Catalogs
    const [filterInput, setFilterInput] = useState('')
    const [filteredData, setFilterData] = useState([])

    const [search, setSearch] = useState('')
    const handleSearch = (e) =>{
        const value = e.target.value
        setFilterInput(value)
        setSearch(value)

        const filtered = projects.filter((project)=>
            project.ini.toLowerCase().includes(value.toLowerCase())
        )
        setFilterData(filtered)
    }

    const [type, setType] = useState(false)
    const handleDropdown = (e) =>{
        const value = e.target.value
        setFilterInput(value)
        setType(value)

        const filtered = projects.filter((project) =>
            project.aim.toString().includes(value.toString())
        )
        setFilterData(filtered)  
    }

    const [Year, setYear] = useState(false)
    const handleYear = (e) =>{
        const value = e.target.value
        setYear(value)
        setFilterInput(value)

        const filtered = projects.filter((project) =>
            project.year.toString().includes(value.toString())
        )
        setFilterData(filtered)
    }

    const types = [
        {
            id: 0,
            label: 'All',
            value: false
        },
        {
            id: 1,
            label: 'Research Projects',
            value: 'Research Projects'
        },
        {
            id: 2,
            label: 'Implementation Projects',
            value: 'Implementation Projects'
        },
        {
            id: 3,
            label: 'Capacity Building Projects',
            value: 'Capacity Building Projects'
        },
    ]

    // const aims = [
    //     {
    //         id: 0,
    //         name: 'All',
    //         pillar: false
    //     },
    //     {
    //         id: 1,
    //         name: 'AIM1: Molecular characterization /epidemiology of pathogens of zoonotic potential',
    //         pillar: 1
    //     },
    //     {
    //         id: 2,
    //         name: 'AIM2: Identification of animal reservoirs',
    //         pillar: 2
    //     },
    //     {
    //         id: 3,
    //         name: 'AIM3: Identification of behavior risks of exposure and susceptible human populations',
    //         pillar: 3
    //     },
    //     {
    //         id: 4,
    //         name: 'AIM4: Identification of biodiversity rich areas/ anthropogenic pressure',
    //         pillar: 4
    //     },
    //     {
    //         id: 5,
    //         name: 'AIM5: New detection tests/ validation',
    //         pillar: 5
    //     },
    // ]

    const years = [
        {
            id: 0,
            year: false,
            label: 'All'
        },
        {
            id:3,
            year: 2024,
            label: 2024
        },
        {
            id:2,
            year: 2023,
            label: 2023
        },
        {
            id: 1,
            year: 2022,
            label: 2022
        },
    ]

    const projects = [
        {
            id:12,
            year: '2023',
            aim: 'Capacity Building Projects',
            imgSrc: ciren,
            ini:'CIREN',
            element: Ciren,
            href: 'CIREN',
            topic:`Centre International de Recherche et d’Éducation environnementales de Nakaï`,
            inform:`LMI PRESTO is the project manager for this scientific research facility on the outskirts of the Nam Theun 2 hydroelectric dam in central Laos. This new infrastructure is being set up by the Nam Theun 2 Power Company (NTPC), the company that manages the dam, in close collaboration with theIRD, the University of Toulouse and several foundations. It includes an observatory for the M-tropics Critical Zone Observatory Network (https://mtropics.obs-mip.fr/), which feeds IPCC data, an applied research centre and an education centre to raise awareness of climate change and biodiversity conservation among young people.
It has received funding of €155k for its construction and funding has been sought from the AUF to set up training courses.
2 Lorasat training courses were held. The aim was to introduce inexpensive techniques for studying and monitoring soil components in real time. In a global context of global change and the need for increased environmental monitoring, there is an urgent need for real-time transmission of the information and data collected so that we can better anticipate risk situations (flooding, spread of disease, etc.) or situations requiring immediate action (switching on an irrigation system, switching on a fan in stables that are too hot, etc.). Generally transmitted to Internet gateways and servers using GSM or WiFi connections, Laos has many remote areas where these technologies cannot be used.
In this situation, the new LPWAN (low power wide area network) communication technologies offer an encouraging alternative, as they are less costly and require less energy to reach the satellites. This is particularly true of the LoRa satellite and its LoRaWAN telecommunications protocol.
A training course on the detection of pathogenic nematodes is scheduled for October 2024, in collaboration with the University of Brno (Czech Republic).
`,
            link1:`https://www.ird.fr/au-ciren-derniere-session-de-la-formation-lorasat-sur-le-deploiement-des-applications-de`,
            link2:`https://en.ird.fr/final-session-cirens-lorasat-training-course-deployment-environmental-monitoring-applications`,
            link3:`https://www.ird.fr/dernier-jour-de-latelier-lorasat-au-ciren`,
            link4:`https://en.ird.fr/last-day-lorasat-workshop-ciren`
        },
        {
            id:11,
            year: '2023',
            aim: 'Implementation Projects',
            imgSrc: onenakai,
            ini:'ONENAKAI',
            topic:'',
            href:'ONENAKAI',
            element: Onenakai,
            inform:`A One-Health approach to estimate the prevalence and genetic diversity of circulating gastrointestinal pathogens among elephants, domesticated cattle and the human population living on the edge of Nakai-Nam Theun National Park, Laos
The aim of the ONENAKAI project was to conduct a pilot study on the Nakai Plateau and around the Nakai Reservoir in Laos, to investigate the presence, the genetic diversity and the circulation of gastro-intestinal parasites in humans, domesticated bovines and elephants, and to determine their potential role as reservoir hosts contributing to the environmental sustenance of zoonotic parasitic infections.
Faecal samples collection has been completed among these three categories, the 40 selected people, owners of domesticated cattle sharing the same territories as wild elephants have received a complete Health check-up and responded to the health questionnaire which is under analyses at the moment.  
All faecal samples have been analyzed for the presence of parasitic eggs in their stool by students at Lao TPHI under the supervision of Prof Somphou Sayasone. A Master student (Ms. Xaiyavong Phengsavanh) and a technician (Mr. Phonekeo Seng Aloun) from the LAOTPHI will start extracting the DNA from the samples collected before the end of March and will subsequently perform microsatellite analyses on wild elephants and domesticated cattle between the months of May and June to determine the number of effective individuals collected. We are finalizing at this very moment the multiplexing approach to address this together with our partners in Malaysia. 
Going beyond the microscopic analyses and adopt a molecular approach to determine the presence and the prevalence of pathogens in stool samples is new to the master students at LaoTPHI. 
During the month of July in collaboration with the Joint International Laboratory PRESTO (Protect-dEtect-STOp) an expert on Loop Mediated Isothermal Amplification (LAMP), Ms. Sayamon Hongjaisee, from Chiang Mai University, Thailand will join us to give a seminar on these procedure and target two pathogens to be analyzed from the stool samples collected. At the end of August, the student selected will be ready to defend her Master Thesis.  In addition, this project, through the set-up of collaborations and validation of research permits in the Khamounane region and within the Nam Theun National Park has allowed us to plant a seed for future collaborations on the circulation of gastrointestinal parasites, which encompasses animal and human Health.
Part of the extracted DNA is going to be sent in April to the faculty of Veterinary medicine at the University of Brno, In the Czech Republic where the PI had established a collaboration in the past working on parasites in great apes of Africa. Under the supervision of Barbora Pafco and Klara Petrzelkova, the samples collected are going to be run with a next generation sequencing approach to compare the circulating strongyles diversity in the three populations categories.  Funding permitting, it is our intention to extend the sampling to a broader area in the Nakai Plateau region. 
`,
            link1:`https://ohsea.ird.fr/en/onenakai/`
        },
        {
            id:10,
            year: '2023',
            aim: 'Capacity Building Projects',
            imgSrc: immana,
            ini:'THE IMMANA project',
            topic:'',
            element: Immana,
            href: `IMMANA`,
            inform:`The IMMANA-funded project “Activity Spaces and Household Exclusion from Food Environments” is led by the University of Reading (Great-Britain) in collaboration with IRD Laos and Chiang Mai University and supported by a two-year £249,000 award from the Innovative Methods and Metrics for Agriculture and Nutrition Actions (IMMANA) programme – coordinated by the London School for Tropical Medicine and Hygiene and co-funded with UK Aid from the UK government through the Foreign, Commonwealth & Development Office (FCDO) and the Bill & Melinda Gates Foundation. The project studied food environments, food security, and the daily food practices of 240 households across eight rural and urban communities in a comparative study between Vientiane Province, Laos, and Chiang Mai Province, Thailand.`,
            link1:`https://en.ird.fr/immana-project-ird-led-team-completes-innovative-data-collection`
        },
        {
            id:9,
            year: '2022',
            aim: ['Implementation Projects','Capacity Building Projects'],
            imgSrc: elaos,
            ini: 'ELAOS',
            href: `ELAOS`,
            element: Elaos,
            topic:'ELephAntuberculOSis (2022-2023): The emergence of tuberculosis at the human-elephant interface',
            inform: `The main objective of this interdisciplinary pilot project was to determine, by adopting a One-Health approach, Mycobacterium tuberculosis prevalence and antibiotic resistance among captive elephants and their mahouts to evaluate the risk of TB emergence at the human-animal interface, in Laos. More specifically, we aimed at developing a reliable, non-invasive diagnostic approach to detect and characterize TB and antibiotic resistance in elephants with a perspective of extending the study to a broader domesticated and wild elephant population. 
Faecal samples collection has been completed, mahouts have received a complete Health check-up and responded to the health questionnaire which is under analyses at the moment.  Optimization of the tuberculosis detection methodological approach is still ongoing at the time of this report. The importance of reliably detecting a tuberculosis infection in a non-invasive way has been welcomed with a lot of enthusiasm and hope by the different organizations involved in the ELAOS study. The implications of a mis-diagnosis and the burden of more invasive approaches is well understood by all parties. In addition, the necessity to address the potential circulation of TB resistant strains becomes an imperative encompassing animal and human health. 
This disease is also threatening other animals, like sun bears (Helarctos malayanus). Recently, a Memorandum of Understanding has been drafted between the NGO Free the Bears, IRD and Centre d’Infectiologie Lao Christophe Mérieux (CILM) in order to officialize and establish a permanent collaboration concerning the routine diagnosis of Tuberculosis in the rescued and captive bear population from Laos. This agreement will be extended to the Elephant Conservation Center in the Sayabury province, which monitors captive elephants but focuses also on the release of the pachyderms back into the wild. Integrating this animal component in the diagnostic offer of the CILM is important for the future of this institute and its visibility as team capable to address the many aspects of One-Health.  
The tuberculosis potential of transmission between elephants and humans is also investigated at the University of Chiang Mai. Thanks to the international Joint Laboratory PRESTO (Protect-dEtect and STOp), coordinated by two researchers in Thailand and the PI of ELAOS in Laos, we were able to integrate and extend this topic of research across the Laotian border. In addition, the guidance from our current partner, the Institut Pasteur du Cambodge, is and will continue to be a precious collaborative and scientific support to tackle tuberculosis spread across the animal kingdom. 
`,
            link1:`https://en.ird.fr/presentation-elaos-research-project`,
            link2:`https://en.ird.fr/kick-start-elaos-project-elephant-conservation-center`,
            link3:`https://www.youtube.com/watch?v=AabGDRXszJQ`
        },
        {
            id:8,
            year: '2022',
            aim: 'Research Projects',
            imgSrc: siren,
            ini:'Survey of zoonotic viruses in wildlife animal and human contracts in Chiang Mai province',
            href: `SIREN`,
            element: Siren,
            topic:'',
            inform:`The majority of emerging pathogens originates in wildlife reservoirs. Transmission to humans occurs particularly in areas where there are close contacts between human and wildlife animals. Southeast Asia (SEA) is a global hotspot ofbiodiversity; 20% of global plant, animal and marine species are found in this region. However, climate change and loss of biodiversity related to human activities and behaviours such as continuing deforestation, land use change, agricultural expansion and intensification, expansion of human settlements, wildlife trade and hunting have contributed to increasing the risk of spillover events, i.e. transmission of a pathogen from a reservoir population to a novel host population. In recent decades, numerous viral zoonoses (i.e. Nipah/Hindra virus infection, H1N1 Swine flu, SARS, COVID-19) have emerged there. Chiang Mai Province is a potential area for zoonotic viral emergence due to its large biodiversity and taking advantage of the forest and wildlife by rural communities such as hunting wildlife for consumption. Large volumes of wildlife animals are hunted to supply restaurants and families. Poor sanitary conditions during food preparation create ideal conditions for spillover events, likely similar to the emergence of SARS-CoV-2 in the human population. Areas where multiple interactions occur between humans and wildlife animals are potential hot spots for zoonotic pathogen transmission. Thus conducting a survey of zoonotic viruses in humans and animals in those areas as well as in rural forest communities may identify spillover events. In addition, analysis of socio-behavior factors will help better understand the risk of transmission to humans and between humans and identify training needs to prevent their transmission in the communities.`
        },
        {
            id:7,
            year: '2022',
            aim: 'Research Projects',
            imgSrc: verdi,
            ini:'Thailand VERDI-RECOVER study Chiang Mai',
            href: `VERDI`,
            element: Verdi,
            topic:'',
            inform:`SARS-CoV-2 outbreak was first reported in the city of Wuhan, China, in December 2019. The WHO declared the virus a Public Health Emergency of International Concern (PHEIC) on 30 January 2020 and a pandemic on 11 March 2020. As of 1 March 2022, more than 578 million cases and 6.4 million deaths have been reported to WHO from all continents.The Thai partners in VERDI, Mahidol University and Chiang Mai University are both leading universities with accredited virology laboratories working to diagnose incident SARS CoV-2 infections in the community and are therefore ideally place to identify eligible participants to invite to participate in this study and enroll participants into this study. Both partners have the infrastructure and capacity to enroll, follow up study participants, securely collect, manage and analyze the data, collect transport and store samples and conduct sequencing of variants.`
        },
        {
            id:6,
            year: '2022',
            aim: 'Implementation Projects',
            imgSrc: inedi,
            ini:'INEDI',
            topic:'',
            href:`INEDI`,
            element: Inedi,
            inform:`In a context of accelerating urban expansion, major changes in land use patterns in Vientiane, the transdisciplinary approach - combining environmental sciences (ecology, biology), social sciences (urban geography) and urbanism – of this project aims to provide a global perspective on waste management issue at a landfill scale. A spotlight on the tight spatial relationships between ecosystems, human activities (including urban planning) and health will be carried out through the implementation of various measures and analyses in order to establish a diagnosis of the extent of pollution and health risks and to prevent a zoonotic disease emergence; to raise awareness of the need to advocate for the One Health Approach in urban areas with the decision-makers and the public at large.The methodology of the research, in particular for all the activities related to social sciences will be elaborated jointly with the Vietnamese team (Hanoi Architectural University) which will share its experience in the spatial and social data collection and treatment.The aim of this project was to carry out surveys among waste pickers at the Vientiane landfill site, in order to estimate the level of nuisance suffered by its collectors and residents living on the edge of the landfill.
The researchers were particularly interested in the impact on their daily lives (food, home economics, ....) and health conditions since the landfill was set up in 2008. The research methodology and all the team's dissemination activities in Laos were carried out jointly with a Vietnamese team from the Hanoi University of Architecture, whose expertise is recognised in the collection of recyclable waste and the definition of the informal sector.
The main results of this survey were presented at a seminar held at the Institut français du Laos in April 2023, illustrated by a photographic exhibition in partnership with the photographer Nicholas Bosoni.
`,
            link1:`https://ohsea.ird.fr/en/inedi/?doing_wp_cron=1720424976.2585949897766113281250`,
            link2:`https://en.ird.fr/sustainable-city-science-day-conference-and-exhibitions-national-university-laos`,
            link3:`https://en.ird.fr/vietnam/projects`
        },
        {
            id:5,
            year: '2022',
            aim: 'Implementation Projects',
            imgSrc: bilao,
            ini:'BILAO',
            topic:'',
            inform:`The aim of the BILAO exploratory research project in Laos was to assess the presence and potential emergence of MBVs (Mosquito-Borne Viruses) associated with domestic birds in rural and peri-urban areas. Using molecular xenomonitoring (a technique for detecting the virus in the excrement of trapped mosquitoes), more than 300 mosquitoes and 30 filter papers containing their excrement were collected.
The next stage will involve continuing the research in Laos by exploring other collection sites in order to target mosquitoes associated with wild avian fauna, as well as extending this type of project to other countries in South-East Asia, in order to better identify the factors responsible for the spread of these viruses in human and animal populations.
`,
            href: `BILAO`,
            element: Bilao,
            link1:`https://ohsea.ird.fr/en/bilao/?doing_wp_cron=1720425122.3644750118255615234375`
        },
        {
            id:4,
            year: '2021',
            aim: 'Capacity Building Projects',
            imgSrc: nutri,
            ini:'The NutriLao project',
            topic:'',
            href: `Nutrilao`,
            element: Nutri,
            inform:`The NutriLao project - also known by its official title as “Development of locally produced, ready to use supplements to improve nutritional status of vulnerable groups in Lao” – is led by IRD researchers, accompanied by researchers from the University of Health Sciences (Laos) and Chiang Mai University (Thailand). NutriLao involved the development of nutritional cereal bars (produced by the social enterprise Mai Savanh Lao), its acceptability testing, and a 4-month efficacy trial with 300 female participants to assess if the micronutrient-enriched food bars help improve their haemoglobin concentrations, anaemia status, and micronutrient status (e.g. zinc and vitamin A). The study was made possible thanks to the IMMANA project research team and the medical team of Dr Vatanaphone Latthaphasavang and builds with whom the researchers cooperate closely. This project is supported by the Fonds d'Innovation pour le Développement (FID).`,
            link1:`https://en.ird.fr/end-study-data-collection-nutrilao-efficacy-trial-has-begun`,
            link2:`https://en.ird.fr/feeding-body-and-mind-partnership-between-ird-and-1000-libraries-laos`,
            link3:`https://en.ird.fr/effectiveness-study-nutrilao-project-has-been-launched`,
            link4:`https://en.ird.fr/nutrilao-acceptability-study-laos-completed-successfully`,
            link5:`https://en.ird.fr/mass-production-nutrilao-phase-2`,
            link6:`https://en.ird.fr/implementation-nutrilao-phase-2-important-stage-implementation-project-na-nguen-village-vientiane`,
            link7:`https://en.ird.fr/distribution-energy-bars-tackle-nutrition-problems-laos`,
            link8:`https://en.ird.fr/nutrilao-sustainable-development-project-supported-fond-dinnovation-pour-le-developpement-fid`

        },
        {
            id:3,
            year: '2021',
            aim: 'Implementation Projects',
            imgSrc: rocket,
            href:`ROCKET`,
            element:Rocket,
            ini:'RoCket Research on Crickets',
            topic:'Low-cost/high-tech production of edible crickets for sustainable agriculture and women empowerment in Laos. (A Private /Public South North Consortium)',
            inform:`The need for new tools to speed up development remains at a high level, in order to generate sources of income for women but also for vulnerable population such as disabled people, elders, and landless farmers.
In this regard, the development of a sustainable edible cricket value chain could offer a great opportunity to address this challenge.
These insects are an important source of protein, requiring a very low investment for a significant return, with almost no waste. In addition, the use of inexpensive sensors to remotely monitor their growth can significantly increase their production yields. Finally, the extraction of high value -added products from their cuticle can also provide producers with a significant income.
The aim of this project is to supports the implementation of cricket breeding activity that is a sustainable activity for local women by providing a perennial income through a farmer's contract with Cricket Lao Farm.
The Project can be categorized as an Integrated Conservation and Development Project.
Its conceptual framework implies the assumption that biodiversity conservation outcomes are inextricably
linked to rural development. We consider that:
•	Alleviating poverty increases the interest and capacity for natural resource conservation (sustainable economic development approach),
•	Offering a new source of regular income can reduce the impact on natural resources gathering
(alternative livelihood approach),
•	Involving local people in the planning and management of natural resources and in the sharing of benefits leads to better livelihood (Participatory planning approach).
At the end, it will provide vulnerable families with a combination of asset management support, productive assets and coaching to strengthen their economic security and resilience link to forest conservation.
Insect farming is a promising way to increase incomes.
The activity is adapted to the local context of targeted Households, and promotes social inclusion for women or persons with disability with limited resources (low labour intensive, no land required). 
`,
            link1:`https://youtu.be/_dob3RdZK-E`,
            link2:`https://en.ird.fr/no-skipping-meals-crickets`,
            link3:`https://en.ird.fr/rocket-project-enters-its-5th-production-cycle`,
            link4:`https://www.ird.fr/les-ecoliers-du-lycee-francais-international-de-vientiane-lfiv-visitent-la-ferme-de-grillons-du`
        },
        {
            id:2,
            year: '2020',
            aim: 'Research Projects',
            imgSrc: lacoviss,
            href: `Lacoviss`,
            element: Lacoviss,
            ini:'LACOVISS (LAo COronavirus Investigation par une Seule Santé)',
            topic:'One-Health approach in Epidemiological investigation of SARS-CoV-2 in Laos.',
            inform:`The Respiratory infection COVID-19, due to a new coronavirus, SARS-CoV2, appeared in December 2019 in several people who attended a wildlife market in Wuhan, China. While COVID-19 has spread to nearly 200 countries and caused over 16 million infections, Lao PDR detected its first confirmed case, three months after the start of the outbreak in China. 
As of today, Lao PDR has only recorded 19 confirmed cases and no death, a very low number compared to other countries around the world.
However, several key factors suggest that Lao PDR could be much more affected by COVID-19 because: 
	The multiple and massive trans-border movements between Lao PDR and China. 
	The similar biogeographical and socio-ecological characteristics with South-Western China. 
	The detection of a high diversity of Betacoronavirus sequences in several species of bats in Lao PDR. The numerous markets selling local wildlife including bats and pangolins.
LACOVISS project aims at investigating, using a ONE-HEALTH approach, this unexpected epidemiological pattern of SARS-CoV-2 in Lao PDR by bringing together an interdisciplinary team of experts in the field from the Institute of Research for Development (IRD), the University of Caen, the Center of Infectiology Lao-Christophe Mérieux (CILM) and the National Animal Health Laboratory (NAHL) in Vientiane. We will focus on a community-based cohort of 1092 households, including 5400 study participants, followed-up between March 2015 and February 2019 for influenza-like illness (ILI) investigation and causative agents detection (LACORIS project), located in the Vientiane metropolitan area. We will track SARS-CoV-2, retrospectively and prospectively, in all potential actors in SARS-CoV-2 circulation, including humans, domestic animals, and wildlife.
`,
            link1:`https://en.ird.fr/node/9136`
        },
        {
            id:1,
            year: '2024',
            aim: 'Research Projects',
            imgSrc: discover,
            ini:'DISCOVER Disentangling the origins of SARS-CoV-2',
            href: `DISCOVER`,
            element: Discover,
            topic:'',
            inform:`The main aim of the project is to monitor the origin of SARS-CoV2 in natural environments sharing biogeographical and socio-ecological characteristics with south-west China (regions of northern Thailand and Lao PDR). The aim is to characterise the natural cycle of SARS-CoV2 and how it emerges in humans. The zoonotic/emergence risk of Sarbecoviruses linked to SARS-CoV2 infecting wild animals in northern Southeast Asia will be estimated using a model that integrates phylodynamic data/analyses with socio-ecological factors, in order to develop genuine strategies for anticipating and preventing future emergences.`
        },
    ]

    // Maximum containers
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerpage, setRowPerPage] = useState(8)

    // calculate data display
    const paginatedData = projects.slice(currentPage * rowsPerpage, (currentPage + 1) * rowsPerpage)
    // Handle page change
    const onPageChange = (e) => {
        setCurrentPage(e.page)
        setRowPerPage(e.rows)
        handleUp()
    }

    const projectRoutes = projects.map((project) => (
        <Route path={project.href} element={<project.element />} key={project.id}/>
    ))
    const dataToDisplay = filterInput ? filteredData : paginatedData
    const totalRecords = filterInput ? filteredData.length : projects.length;

    return(
        <div className="project">
            <div className="people-modal" style={{display: active? 'block' : 'none'}} onClick={handlePopup}></div>
            <div className="cover">
                <div className="cover-bg"></div>
                <div className="cover-text flyIn">
                    <h1>PROJECTS</h1>
                </div>
            </div>

            <Routes>
                <Route index element={
                   <div className='container'>
                    <div className="filter">
                        <div className="items search">
                            <div className="head">
                                <b>Search: </b>
                            </div>
                            <div className="search">
                                <InputText type="text" placeholder="enter search term..." onChange={handleSearch} value={search} disabled={type !== false || Year !== false}/>
                                <i className="pi pi-search"></i>
                            </div>
                        </div>
                            <div className="dropdown">
                                {/* FILTER by AIMS */}
                                    <div className="items dropdown aims">
                                        <div className="head">
                                            <b>Type: </b>
                                        </div>
                                        <div className="aims">
                                            <Dropdown value={type} onChange={handleDropdown} options={types} optionLabel='label' optionValue='value' placeholder='All' disabled={search !== '' || Year !== false}/>
                                        </div>
                                    </div>
                                {/* FILTER by YEAR */}
                                <div className="items dropdown year">
                                    <div className="head">
                                        <b>Year: </b>
                                    </div>
                                    <div className="year">
                                        <Dropdown value={Year} onChange={handleYear} options={years} optionLabel='label' optionValue='year' placeholder='All' disabled={search !== '' || type !== false}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="project-activities">
                                {/* Search input */}
                            {dataToDisplay.map((project) => (
                            <div className={`containers ${project.id}`} key={project.id}>
                                <div className="items">
                                <div className="img">
                                    <img src={project.imgSrc} alt="item" loading='lazy'/>
                                    {/* <div className="tags">
                                        {Array.isArray(project.aim) && project.aim.map((aim) => (
                                            <div className="aim">
                                                {aim}
                                            </div>
                                        ))}
                                        <div className="year">
                                            {project.year}
                                        </div>
                                    </div> */}
                                </div>
                                <div className='ini'>
                                    <h2>{project.ini}</h2>
                                </div>
                                <div className='inform'>
                                    <p className='text'>{project.inform}</p>
                                </div>
                                <div className="btn">
                                    <Link to={`/projects/${project.href}`} id={project.href}>SEE MORE</Link>
                                </div>
                                </div>
                            </div>
                            ))}
                            
                        </div>    
                        <div className="paginator">
                            <Paginator
                                first={currentPage * rowsPerpage}
                                rows={rowsPerpage}
                                totalRecords={totalRecords}
                                onPageChange={onPageChange}
                            />
                        </div>
                   </div>
                }/>
                {projectRoutes}
                <Route path='*' element={<ErrorPage/>} />
            </Routes> 

        </div>
    )
}

export default Projects