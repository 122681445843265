import React from 'react';
import '../projects-pages.css' 

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpg'
import pic3 from './pic3.jpg'

const Elaos = () => {

    const detail = {
        id:1,
        imgSrc: `${process.env.PUBLIC_URL}/research/ELAOS/cover.jpg`,
        ini: 'ELAOS',
        topic:'ELephAntuberculOSis (2022-2023): The emergence of tuberculosis at the human-elephant interface',
        inform: `The main objective of this interdisciplinary pilot project was to determine, by adopting a One-Health approach, Mycobacterium tuberculosis prevalence and antibiotic resistance among captive elephants and their mahouts to evaluate the risk of TB emergence at the human-animal interface, in Laos. More specifically, we aimed at developing a reliable, non-invasive diagnostic approach to detect and characterize TB and antibiotic resistance in elephants with a perspective of extending the study to a broader domesticated and wild elephant population. 
Faecal samples collection has been completed, mahouts have received a complete Health check-up and responded to the health questionnaire which is under analyses at the moment.  Optimization of the tuberculosis detection methodological approach is still ongoing at the time of this report. The importance of reliably detecting a tuberculosis infection in a non-invasive way has been welcomed with a lot of enthusiasm and hope by the different organizations involved in the ELAOS study. The implications of a mis-diagnosis and the burden of more invasive approaches is well understood by all parties. In addition, the necessity to address the potential circulation of TB resistant strains becomes an imperative encompassing animal and human health. 
This disease is also threatening other animals, like sun bears (Helarctos malayanus). Recently, a Memorandum of Understanding has been drafted between the NGO Free the Bears, IRD and Centre d’Infectiologie Lao Christophe Mérieux (CILM) in order to officialize and establish a permanent collaboration concerning the routine diagnosis of Tuberculosis in the rescued and captive bear population from Laos. This agreement will be extended to the Elephant Conservation Center in the Sayabury province, which monitors captive elephants but focuses also on the release of the pachyderms back into the wild. Integrating this animal component in the diagnostic offer of the CILM is important for the future of this institute and its visibility as team capable to address the many aspects of One-Health.  
The tuberculosis potential of transmission between elephants and humans is also investigated at the University of Chiang Mai. Thanks to the international Joint Laboratory PRESTO (Protect-dEtect and STOp), coordinated by two researchers in Thailand and the PI of ELAOS in Laos, we were able to integrate and extend this topic of research across the Laotian border. In addition, the guidance from our current partner, the Institut Pasteur du Cambodge, is and will continue to be a precious collaborative and scientific support to tackle tuberculosis spread across the animal kingdom. 
`,
        link1:`https://en.ird.fr/presentation-elaos-research-project`,
        link2:`https://en.ird.fr/kick-start-elaos-project-elephant-conservation-center`,
        link3:`https://www.youtube.com/watch?v=AabGDRXszJQ`
    }
    const handleLink1 = () =>{
        window.open(detail.link1, '_blank', 'noreferrer')
    }
    const handleLink2 = () =>{
        window.open(detail.link2, '_blank', 'noreferrer')
    }
    const handleLink3 = () =>{
        window.open(detail.link3, '_blank', 'noreferrer')
    }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
        },
        {
            imageSrc: pic3,
            alt: 'pic3',
            title: 'pic3',
        }
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{detail.topic}</h2>
                <p>{detail.inform}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                    <button onClick={handleLink2}>{detail.link2}</button>
                    <button className='youtube' onClick={handleLink3}><i className='pi pi-youtube'></i></button>
                </div>
            </div>
        </div>
    );
}

export default Elaos;
