import React from 'react';
import '../../news-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.JPG'
import pic1 from './pic1.JPG'


const Id5 = () => {

    const detail = 
        {
            id:5,
            imgSrc:cover,
            ini:'Preparing to respond to calls for tender',
            href:'',
            element:'',
            topic:'',
            inform:`In May 2023, discussion sessions were held during which the partners had the opportunity to express their needs and expectations of this new research network, in particular how to integrate the One Heath approach, which studies health from the human, animal and environmental perspectives, into their research projects.
Around thirty participants exchanged ideas to identify a research question relevant to all the members present. Two concept notes emerged from these discussions, with a view to responding to calls for projects in general and the PREZODE initiative in particular.
`,
            link1:`https://lucent.ams.cmu.ac.th/presto.php`
}
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
            thumbnailImageSrc: cover
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
            thumbnailImageSrc: pic1
        }
    ]

    return (
        <div className='elements containers'>
             <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{Parse(detail.topic)}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                </div>
            </div>
        </div>
    );
}

export default Id5;
