import React from 'react';
import '../../news-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpg'


const Id1 = () => {

    const detail = 
        {
            id:1,
            imgSrc:cover,
            ini:'Engaging Laotian pharmacy students and their teachers in the fight against malnutrition',
            href: `Engaging Laotian pharmacy students`,
            element: ``,
            topic:'',
            inform:`Last July 19, 90 participants gathered at the Faculty of Pharmacy of the 
            University of Health Sciences (UHS) in Vientiane to share knowledge on two IRD-led 
            nutrition-related research projects in Laos: the IMMANA and NutriLao studies.
The Faculty of Pharmacy has been a major partner throughout the IMMANA and NutriLao projects, 
contributing distinctly to their success through administrative support as well as the 
secondment of highly qualified specialist staff members. The completion of the research offered 
a unique opportunity to share methodological approaches, experiences, and lessons learned with the next generation of scholarly minds in Laos. Testimonies and feedbacks from researchers inspired students on the practical problems they can tackle with these research methodologies, and how to translate them into actionable interventions and policies. Owing to the One Health relevance of nutrition and food safety in zoonotic diseases and the cross-boundary work of these projects – linking research in Chiang Mai, Thailand, with Vientiane Province, Laos – the event was also supported by the LMI Presto.
`,
            link1:`https://en.ird.fr/engaging-laotian-pharmacy-students-and-their-teachers-fight-against-malnutrition`
        }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
            thumbnailImageSrc: cover
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
            thumbnailImageSrc: pic1
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
            thumbnailImageSrc: pic2
        }
    ]

    return (
        <div className='elements containers'>
           <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{Parse(detail.topic)}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                </div>
            </div>
        </div>
    );
}

export default Id1;
