import React from 'react';
import '../../news-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpg'


const Id6 = () => {

    const detail = 
        {
            id:6,
            imgSrc:cover,
            ini:'Participatory approach in the context of One Health Social Sciences',
            href:'',
            element:'',
            topic:'',
            inform:`In collaboration with the PRESTO team from Chiang Mai, a two-day workshop on the participatory approach in the context of One Health was held in Vientiane. Its aims were to explore the theoretical and methodological aspects of qualitative research in the context of public health in Laos, and to develop research skills through lectures and practical exercises. It was attended by 89 participants.`,
            link1:`https://en.ird.fr/launch-presto-international-joint-laboratory-collaborative-strategy-one-health-projects-building`
       }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
            thumbnailImageSrc: cover
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
            thumbnailImageSrc: pic1
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
            thumbnailImageSrc: pic2
        }
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{Parse(detail.topic)}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                </div>
            </div>
        </div>
    );
}

export default Id6;
