import { Handle, Position } from '@xyflow/react';


function OutputNode({ data, isConnectable }) {

  return (
    <div className="output-node" style={{backgroundColor: data.bgcolor}}>
      <Handle
        id='top'
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <Handle
        id='right'
        type="target"
        position={Position.Right}
        isConnectable={isConnectable}
      />
      <div className='container'>
        <div className="items">
           <a href={data.link} rel='noreferrer' target='_blank'><h3>{data.value}</h3></a>
            <div className="ul">
                <ul>
                {Array.isArray(data.li) && data.li.map((datas) => (
                    <li className="li">
                        {datas}
                    </li>
                ))}
                </ul>
            </div>
        </div>
      </div>
      <Handle
        type="target"
        position={Position.Bottom}
        id="bottom"
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position={Position.Left}
        id="left"
        isConnectable={isConnectable}
      />
    </div>

    
  );
}

export default OutputNode;
