// Header
import ird from './logo/ird-logo.png'
import ams from './logo/ams_eng_ver.png'
import ird80 from './logo/ird80.jpg'

import prestologo from './logo/presto-logo-color.png'
import prestogrey from './logo/presto-logo.png'

import './App.css';

import { HashRouter, Routes, Route, useLocation } from 'react-router-dom'
import Layout from './pages/layout'
import Home from './pages/home'
import Aboutus from './pages/aboutus'
import Projects from './pages/projects'
import News from './pages/news'
import Publications from './pages/publication'
import ErrorPage from './pages/error'
import Platforms from './pages/platforms';

import React, { useEffect, useState } from 'react'

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top:0,
      behavior: 'smooth'
    }); // Reset scroll position to top
  }, [pathname]);

  return null;
};
export default function App() {
  // Password required
  // const [authorized, setAuthorized] = useState(false)
  // const [password, setPassword] = useState('')

  // const handlePassword = () =>{
  //   if(password === "PresTo!2024"){
  //     setAuthorized(true)
  //   }else{
  //     alert('incorrect password!')
  //   }
  // }
  
  // if(!authorized){
  //   return(
  //     <div style={{ textAlign: 'center', marginTop: '20%' }}>
  //       <h2>Please Enter Password</h2>
  //       <input
  //         type="password"
  //         value={password}
  //         onChange={(e) => setPassword(e.target.value)}
  //       />
  //       <button onClick={handlePassword}>Submit</button>
  //     </div>
  //   )
  // }


  return (
    <div className="App">
      
        <div className="header-logo">
          <a href="https://en.ird.fr/" id='ird' target='_blank' rel='noreferrer'>
            <img src={ird80} alt="ird" />
            <img src={ird} alt="ird" />
          </a>

          <a href="/" id='presto'>
            <img src={prestologo} alt="presto" />
          </a>
        
          <a href="https://www.ams.cmu.ac.th/main/web/" target='_blank' rel='noreferrer'>
            <img src={ams} id="ams" alt="ams" />
          </a>
        </div>
        
        <HashRouter>
          <ScrollToTop/>
          <Routes>
            <Route path='/' element={<Layout/>}>
              <Route index element={<Home/>}/>
              <Route path='aboutus' element={<Aboutus/>}/>
              <Route path='projects/*' element={<Projects/>}/>
              <Route path='news/*' element={<News />} />
              <Route path='platforms' element={<Platforms />}/>
              <Route path='publications' element={<Publications />}/>
              <Route path='*' element={<ErrorPage/>}/>
            </Route>
          </Routes>
        </HashRouter>
       
        

        <div className="footer">
          <div className="img">
            <img src={prestogrey} alt="logo" />
          </div>
          <div className="lucent">
            <div className="text">
              <div className="head">
                <b>LUCENT international collaboration</b>
              </div>
              <div className="details">
                <ul>
                  <li>AMS Integrated Academic Excellence Center (AMS-IAEC)</li>
                  <li>239 Huay Kaew Road, Suthep, </li>
                  <li> Chiang Mai, Thailand 50200</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="irdlaos">
            <div className="text">
              <div className="head">
                <b>IRD LAOS</b>
              </div>
              <div className="details">
                <ul>
                  <li>Ban Naxai, Saysettha District </li>
                  <li>P.O. Box 5992, Vientiane, Lao PDR</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </div>

  );
}


