import React from 'react';
import '../projects-pages.css'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpg'
import pic3 from './pic3.jpg'
import pic4 from './pic4.jpg'
import pic5 from './pic5.jpg'
import pic6 from './pic6.jpg'
import pic7 from './pic7.JPG'

const Nutri = () => {
   
    const detail = 
        {
            id:6,
            imgSrc:`${process.env.PUBLIC_URL}/research/Nutrilao/cover.jpg`,
            ini:'The NutriLao project',
            topic:'',
            inform:`The NutriLao project - also known by its official title as “Development of locally produced, ready to use supplements to improve nutritional status of vulnerable groups in Lao” – is led by IRD researchers, accompanied by researchers from the University of Health Sciences (Laos) and Chiang Mai University (Thailand). NutriLao involved the development of nutritional cereal bars (produced by the social enterprise Mai Savanh Lao), its acceptability testing, and a 4-month efficacy trial with 300 female participants to assess if the micronutrient-enriched food bars help improve their haemoglobin concentrations, anaemia status, and micronutrient status (e.g. zinc and vitamin A). The study was made possible thanks to the IMMANA project research team and the medical team of Dr Vatanaphone Latthaphasavang and builds with whom the researchers cooperate closely. This project is supported by the Fonds d'Innovation pour le Développement (FID).`,
            link1:`https://en.ird.fr/end-study-data-collection-nutrilao-efficacy-trial-has-begun`,
            link2:`https://en.ird.fr/feeding-body-and-mind-partnership-between-ird-and-1000-libraries-laos`,
            link3:`https://en.ird.fr/effectiveness-study-nutrilao-project-has-been-launched`,
            link4:`https://en.ird.fr/nutrilao-acceptability-study-laos-completed-successfully`,
            link5:`https://en.ird.fr/mass-production-nutrilao-phase-2`,
            link6:`https://en.ird.fr/implementation-nutrilao-phase-2-important-stage-implementation-project-na-nguen-village-vientiane`,
            link7:`https://en.ird.fr/distribution-energy-bars-tackle-nutrition-problems-laos`,
            link8:`https://en.ird.fr/nutrilao-sustainable-development-project-supported-fond-dinnovation-pour-le-developpement-fid`

       }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }
        const handleLink2 = () =>{
            window.open(detail.link2, '_blank', 'noreferrer')
        }
        const handleLink3 = () =>{
            window.open(detail.link3, '_blank', 'noreferrer')
        }
        const handleLink4 = () =>{
            window.open(detail.link4, '_blank', 'noreferrer')
        }
        const handleLink5 = () =>{
            window.open(detail.link5, '_blank', 'noreferrer')
        }
        const handleLink6 = () =>{
            window.open(detail.link6, '_blank', 'noreferrer')
        }
        const handleLink7 = () =>{
            window.open(detail.link7, '_blank', 'noreferrer')
        }
        const handleLink8 = () =>{
            window.open(detail.link8, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
        },
        {
            imageSrc: pic1,
            alt: 'pic1',
            title: 'pic1',
        },
        {
            imageSrc: pic2,
            alt: 'pic2',
            title: 'pic2',
        },
        {
            imageSrc: pic3,
            alt: 'pic3',
            title: 'pic3',
        },{
            imageSrc: pic4,
            alt: 'pic4',
            title: 'pic4',
        },{
            imageSrc: pic5,
            alt: 'pic5',
            title: 'pic5',
        },{
            imageSrc: pic6,
            alt: 'pic6',
            title: 'pic6',
        },{
            imageSrc: pic7,
            alt: 'pic7',
            title: 'pic7',
        }
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{detail.topic}</h2>
                <p>{detail.inform}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                    <button onClick={handleLink2}>{detail.link2}</button>
                    <button onClick={handleLink3}>{detail.link3}</button>
                    <button onClick={handleLink4}>{detail.link4}</button>
                    <button onClick={handleLink5}>{detail.link5}</button>
                    <button onClick={handleLink6}>{detail.link6}</button>
                    <button onClick={handleLink7}>{detail.link7}</button>
                    <button onClick={handleLink8}>{detail.link8}</button>
                </div>
            </div>
        </div>
    );
}

export default Nutri;
