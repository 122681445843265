import React from 'react';
import '../../news-pages.css'
import Parse from 'html-react-parser'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import cover from './cover.jpg'
import img1 from './img1.jpg'
import img2 from './img2.jpg'
import img3 from './img3.jpg'
import img4 from './img4.jpg'

const Page = () => {

    const detail = 
        {
            id:1,
            imgSrc:cover,
            ini:'Explore oppotunities and strategies for improving education and research with Department of Parasitology, Faculty of Medicine, Chiang Mai University',
            href:'',
            element:'',
            topic:'',
            inform: `On December 11, 2024, Dr. Dorothée Missé, Dr.Dorothée Missé co-heads the “Biology of Viral Infection” Department at IRD MIVEGEC Unit, France
            met with the Department of Parasitology, Faculty of Medicine, Chiang Mai University, to explore opportunities and strategies for developing education and research collaboration.`,
            link1: `https://www.facebook.com/permalink.php?story_fbid=pfbid02JvpUFL9uAPSV4UNV2wyHGhcExRwCoB36ZzKSkUcnF4qAu7zcgLN5k3J41ud9i3zKl&id=100089824854973`,
        }
    
        const handleLink1 = () =>{
            window.open(detail.link1, '_blank', 'noreferrer')
        }

    const imgs = [
        {
            imageSrc: cover,
            alt: 'cover',
            title: 'cover',
            thumbnailImageSrc: cover
        },
        {
            imageSrc: img1,
            alt: 'img1',
            title: 'img1',
            thumbnailImageSrc: img1
        },
        {
            imageSrc: img2,
            alt: 'img2',
            title: 'img2',
            thumbnailImageSrc: img2
        },
        {
            imageSrc: img3,
            alt: 'img3',
            title: 'img3',
            thumbnailImageSrc: img3
        },
        {
            imageSrc: img4,
            alt: 'img4',
            title: 'img4',
            thumbnailImageSrc: img4
        },
    ]

    return (
        <div className='elements containers'>
            <div className="img-slicer">
                <OwlCarousel
                    className='owl-theme'
                    loop={true}
                    margin={10}
                    lazyLoad={true}
                    nav
                    items={1}
                    autoplay={true}
                    autoplayTimeout={3000}
                    autoplayHoverPause={true}
                    smartSpeed={1000}
                >
                    {imgs.map((img,index)=>(
                        <div className="item" key={index}><img src={img.imageSrc} alt="item" /></div>
                    ))}
                </OwlCarousel>
            </div>
            <div className="text">
                <h1>{detail.ini}</h1>
                <h2>{Parse(detail.topic)}</h2>
                <p>{Parse(detail.inform)}</p>
                <div className="btn">
                    <h2>MORE INFORMATION</h2>
                    <button onClick={handleLink1}>{detail.link1}</button>
                </div>
            </div>
        </div>
    );
}

export default Page;
