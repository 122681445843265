import './css/platforms.css'
import { useState } from 'react'

import Mindmap from './platforms/mindmap'

const Platforms = () => {
    
        const [active, setActive] = useState('')
        const handlePopup = (e) =>{
            const key = e.currentTarget.getAttribute('data-key')
            setActive(prevActive => prevActive === key ? '' : key)
        }
        

    return (
        <div className='platforms'>
            <div className="people-modal" style={{display: active? 'block' : 'none'}} onClick={handlePopup}></div>
            <div className="cover">
                <div className="cover-bg"></div>
                <div className="cover-text flyIn">
                    <h1>PLATFORMS</h1>
                </div>
            </div>
            <div className="mindmapping">
                <Mindmap/>
            </div>
        </div>
    )
}

export default Platforms